import React, { Component } from 'react';
import { NavbarHeader } from './components/NavbarHeader';
import { Couple } from './components/Couple';
import { Gallery } from './components/Gallery';
import { SaveTheDate } from './components/SaveTheDate';
import { Slider } from './components/Slider';
import { Video } from './components/Video';
import { Counter } from './components/Counter';
import { Footer } from './components/Footer';
import { WeddingParty } from './components/WeddingParty';
import { Schedule } from './components/Schedule';
import { Social } from './components/Social';
import { Map } from './components/Map';

interface IProps {
    sectionName: string; // section
    data?: any;
}

class SectionSelector extends Component<IProps> {

    components = {
        NavData: NavbarHeader,
        GalleryData: Gallery,
        SliderData: Slider,
        VideoData: Video,
        SaveTheDateData: SaveTheDate,
        CoupleData: Couple,
        CounterData: Counter,
        FooterData: Footer,
        WeddingPartyData: WeddingParty,
        ScheduleData: Schedule,
        SocialData: Social,
        MapData: Map,

    };

    render() {
        const TagName = (this.components as any)[this.props.sectionName || 'empty'];
        return <TagName
            data={this.props.data}
        />
    }
}

export default SectionSelector;