import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Offcanvas,
    CloseButton,
} from 'react-bootstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface NavItem {
    item?: string;
}
interface Data {
    logoText?: string;
    navItem?: NavItem[];
    buttonText?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class NavbarHeader extends React.Component<IProps, {}> {
    state = {
        mainColor:"#35A997",
        isOpen: false,
        headerShrink: "cnst-header",
        navbarNAV: "cnst-navbar-nav",
        navbarNavRight: "cnst-navbar-nav-right",
    };

    constructor(props: any) {
        super(props);

        this.state = {
            mainColor: "#35A997",
            isOpen: false,
            headerShrink: "cnst-header",
            navbarNAV: "cnst-navbar-nav",
            navbarNavRight: "cnst-navbar-nav-right"
        };
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 200;

        if (distanceY > shrinkOn) {
            this.setState({ headerShrink: "cnst-header1" });
            this.setState({ logoShrink: "" });
            this.setState({ navbarNAV: "cnst-navbar-navShrink" });
            this.setState({ navbarNavRight: "cnst-navbar-navShrink-right" });
        } else {
            this.setState({ headerShrink: "cnst-header" });
            this.setState({ logoShrink: "cnst-logoBrand" });
            this.setState({ navbarNAV: "cnst-navbar-nav" });
            this.setState({ navbarNavRight: "cnst-navbar-nav-right" });
        }
    }
    
    render() {
        const nav = this.props.data;
        if (nav !== undefined && nav !== null) {
            //About Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForNavData = nav.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let NavDataObj: Data[] = [];
            let NavItemObj: NavItem[] = [];

            var count = Object.keys(groupByMetaGroupForNavData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { logoText: "" };
                let item: NavItem = { item: "" };

                groupByMetaGroupForNavData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "LogoText") {
                        dt.logoText = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.buttonText = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "NavItem") {
                        item.item = data.MetaProperty.value;
                        NavItemObj.push(item);
                    }

                });
                NavDataObj.push(dt);

            }

        return (
            <div>
                <Container fluid className="px-0">
                    <div className={this.state.headerShrink} >
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Navbar key="lg" expand="lg" className="mb-3">
                                    <Container>
                                        <Navbar.Brand href="/conference1" className="">
                                            <h1 className="cnst-navLogoText">{NavDataObj[0].logoText}</h1>
                                        </Navbar.Brand>
                                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}> <i className="fa fa-bars"></i> </Navbar.Toggle>
                                        <Navbar.Offcanvas
                                            id={`offcanvasNavbar-expand-lg`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                                            placement="end"
                                        >
                                            <Offcanvas.Header closeButton closeVariant="white">
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                                                    {NavDataObj[0].logoText}
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>
                                                <Nav className={this.state.navbarNAV}>
                                                    <Nav.Link className="cnst-navlink" href="#top">Top</Nav.Link>
                                                    <Nav.Link className="cnst-navlink" href="#about">ABOUT</Nav.Link>
                                                    <Nav.Link className="cnst-navlink" href="#speaker">SPEAKER</Nav.Link>
                                                    <Nav.Link className="cnst-navlink" href="#sponsor">SPONSOR</Nav.Link>
                                                    <Nav.Link className="cnst-navlink" href="#pricing">PRICING</Nav.Link>
                                                    <Nav.Link className="cnst-navlink" href="#contact">CONTACT</Nav.Link>
                                                    <a href="#" className="cnst-confer-btn">Register <i className="fa fa-long-arrow-right"></i></a>
                                                </Nav>
                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
    }
}