import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class Video extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="video">
                <div className="act-video-bg">
                    <button className="wrap"><i className="fa fa-play act-play-btn" aria-hidden="true"></i></button>
                </div>
            </div>
        );
    }
}