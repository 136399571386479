import React from 'react';
import Form from 'react-bootstrap/Form';
import { LandingPageSectionBranding } from '../../../../server/LandingPageModel';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../../../store/store';
import { weddingActionCreators } from '../../state/weddingSlice';

interface IProps extends PropsFromRedux {
    themeSectionData: LandingPageSectionBranding,
    sectionName: any
}

export class Customize extends React.Component<IProps, {}> {
    state = {
        BackgroundImage: "",
        BackgroundImageOpacity: "",
        ColorATitle: "",
        ColorAOneLabel: "",
        ColorAOne: "",
        ColorATwoLabel: "",
        ColorATwo: "",
        ColorAThreeLabel: "",
        ColorAThree: "",
        ColorAFourLabel: "",
        ColorAFour: "",
        ColorBTitle: "",
        ColorBOneLabel: "",
        ColorBOne: "",
        ColorBTwoLabel: "",
        ColorBTwo: "",
        ColorBThreeLabel: "",
        ColorBThree: "",
        ColorBFourLabel: "",
        ColorBFour: "",
        ColorCTitle: "",
        ColorCOneLabel: "",
        ColorCOne: "",
        ColorCTwoLabel: "",
        ColorCTwo: "",
        ColorCThreeLabel: "",
        ColorCThree: "",
        ColorCFourLabel: "",
        ColorCFour: "",

        FontAFamily: "",
        FontASize: "",
        FontAStyle: "",
        FontABold: "",
        FontAFamilyLabel: "",
        FontBFamily: "",
        FontBSize: "",
        FontBStyle: "",
        FontBBold: "",
        FontBFamilyLabel: "",
        FontCFamily: "",
        FontCSize: "",
        FontCStyle: "",
        FontCBold: "",
        FontCFamilyLabel: "",
    };
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.kitColorFontChange = this.kitColorFontChange.bind(this);
        this.handleRangeChange = this.handleRangeChange.bind(this);
        this.state = {
            BackgroundImage: this.props.themeSectionData.BackgroundImage,
            BackgroundImageOpacity: this.props.themeSectionData.BackgroundImageOpacity,
            ColorATitle: this.props.themeSectionData.ColorATitle,
            ColorAOneLabel: this.props.themeSectionData.ColorAOneLabel,
            ColorAOne: this.props.themeSectionData.ColorAOne,
            ColorATwoLabel: this.props.themeSectionData.ColorATwoLabel,
            ColorATwo: this.props.themeSectionData.ColorATwo,
            ColorAThreeLabel: this.props.themeSectionData.ColorAThreeLabel,
            ColorAThree: this.props.themeSectionData.ColorAThree,
            ColorAFourLabel: this.props.themeSectionData.ColorAFourLabel,
            ColorAFour: this.props.themeSectionData.ColorAFour,

            ColorBTitle: this.props.themeSectionData.ColorBTitle,
            ColorBOneLabel: this.props.themeSectionData.ColorBOneLabel,
            ColorBOne: this.props.themeSectionData.ColorBOne,
            ColorBTwoLabel: this.props.themeSectionData.ColorBTwoLabel,
            ColorBTwo: this.props.themeSectionData.ColorBTwo,
            ColorBThreeLabel: this.props.themeSectionData.ColorBThreeLabel,
            ColorBThree: this.props.themeSectionData.ColorBThree,
            ColorBFourLabel: this.props.themeSectionData.ColorBFourLabel,
            ColorBFour: this.props.themeSectionData.ColorBFour,

            ColorCTitle: this.props.themeSectionData.ColorCTitle,
            ColorCOneLabel: this.props.themeSectionData.ColorCOneLabel,
            ColorCOne: this.props.themeSectionData.ColorCOne,
            ColorCTwoLabel: this.props.themeSectionData.ColorCTwoLabel,
            ColorCTwo: this.props.themeSectionData.ColorCTwo,
            ColorCThreeLabel: this.props.themeSectionData.ColorCThreeLabel,
            ColorCThree: this.props.themeSectionData.ColorCThree,
            ColorCFourLabel: this.props.themeSectionData.ColorCFourLabel,
            ColorCFour: this.props.themeSectionData.ColorCFour,

            FontAFamily: this.props.themeSectionData.FontAFamily,
            FontASize: this.props.themeSectionData.FontASize,
            FontAStyle: this.props.themeSectionData.FontAStyle,
            FontABold: this.props.themeSectionData.FontABold,
            FontAFamilyLabel: this.props.themeSectionData.FontAFamilyLabel,
            FontBFamily: this.props.themeSectionData.FontBFamily,
            FontBSize: this.props.themeSectionData.FontBSize,
            FontBStyle: this.props.themeSectionData.FontBStyle,
            FontBBold: this.props.themeSectionData.FontBBold,
            FontBFamilyLabel: this.props.themeSectionData.FontBFamilyLabel,
            FontCFamily: this.props.themeSectionData.FontCFamily,
            FontCSize: this.props.themeSectionData.FontCSize,
            FontCStyle: this.props.themeSectionData.FontCStyle,
            FontCBold: this.props.themeSectionData.FontCBold,
            FontCFamilyLabel: this.props.themeSectionData.FontCFamily,

        };
        document.body.style.setProperty('--' + this.props.sectionName + '-bg-img', 'url(' + this.props.themeSectionData.BackgroundImage + ')');
        document.body.style.setProperty('--' + this.props.sectionName + '-color-a-one', this.props.themeSectionData.ColorAOne)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-a-two', this.props.themeSectionData.ColorATwo)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-a-three', this.props.themeSectionData.ColorAThree)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-a-four', this.props.themeSectionData.ColorAFour)

        document.body.style.setProperty('--' + this.props.sectionName + '-color-b-one', this.props.themeSectionData.ColorBOne)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-b-two', this.props.themeSectionData.ColorBTwo)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-b-three', this.props.themeSectionData.ColorBThree)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-b-four', this.props.themeSectionData.ColorBFour)

        document.body.style.setProperty('--' + this.props.sectionName + '-color-c-one', this.props.themeSectionData.ColorCOne)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-c-two', this.props.themeSectionData.ColorCTwo)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-c-three', this.props.themeSectionData.ColorCThree)
        document.body.style.setProperty('--' + this.props.sectionName + '-color-c-four', this.props.themeSectionData.ColorCFour)

        document.body.style.setProperty('--' + this.props.sectionName + '-a-fontFamily', this.props.themeSectionData.FontAFamily)
        document.body.style.setProperty('--' + this.props.sectionName + '-b-fontFamily', this.props.themeSectionData.FontBFamily)
        document.body.style.setProperty('--' + this.props.sectionName + '-c-fontFamily', this.props.themeSectionData.FontCFamily)

    }
    componentDidMount() {

    }


    kitColorFontChange = (value: any, item: any, stateName: any) => {
        var idx = this.props.LandingPageDTO.LandingPageSections.findIndex(i => i.LandingPageSectionId == this.props.sectionId)

        var t = this.props.LandingPageDTO.LandingPageSections[idx].LandingPageSectionBranding


        if (stateName === "FontABold" || stateName === "FontBBold" || stateName === "FontCBold") {
            document.body.style.setProperty(item, value === true ? "bold" : "normal");
            var c = { ...t, [stateName]: value === true ? "bold" : "normal" };
            this.setState({ [stateName]: value === true ? "bold" : "normal" });
        }
        else {
            document.body.style.setProperty(item, value);
            var c = { ...t, [stateName]: value }
        }

        this.props.setThemeSettings(c, idx)

    }

    handleChangeMultipleImag(e: React.ChangeEvent<HTMLInputElement>) {

        const target = e.target as HTMLInputElement;
        if (target.files && target.files.length) {

            const file1 = target.files[0];
            const url1 = URL.createObjectURL(file1);
            const file2 = target.files[1];
            var url2 = "";
            if (file2 !== undefined) {
                url2 = URL.createObjectURL(file2);
            }
            else {
                url2 = URL.createObjectURL(file1);
            }

            var idx = this.props.LandingPageDTO.LandingPageSections.findIndex(i => i.LandingPageSectionId == this.props.sectionId)

            var t = this.props.LandingPageDTO.LandingPageSections[idx].LandingPageSectionBranding

            var mimageList = ["" + url1 + "", "" + url2 + ""];

            var c = { ...t, "MultipleImages": mimageList }

            this.props.setThemeSettings(c, idx)
        }
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        //debugger;
        const target = e.target as HTMLInputElement;
        if (target.files && target.files.length) {
            const file = target.files[0];
            const url = URL.createObjectURL(file);
            console.log(url);
            document.body.style.setProperty('--' + this.props.sectionName + '-bg-img', 'url(' + url + ')');

        }
    }

    handleRangeChange(prop: any, event: any) {

        this.setState({ BackgroundImageOpacity: event.target.value });

        var idx = this.props.LandingPageDTO.LandingPageSections.findIndex(i => i.LandingPageSectionId == this.props.sectionId)

        var t = this.props.LandingPageDTO.LandingPageSections[idx].LandingPageSectionBranding

        var c = { ...t, BackgroundImageOpacity: event.target.value }

        this.props.setThemeSettings(c, idx)
    }
    kitGenerateJSON = () => {

        console.log("Customized JSON of Kit", this.props.LandingPageDTO);

    }

    render() {

        return (
            <div className="col-md-12 mb-4">

                <div className="st-sec-cust pb-2">
                    {(this.props.themeSectionData?.ColorAOne !== "NotUsed" || this.props.themeSectionData?.ColorATwo !== "NotUsed" || this.props.themeSectionData?.ColorAThree !== "NotUsed" || this.props.themeSectionData?.ColorAFour !== "NotUsed") &&

                        <div className="row p-2 pt-0 text-center mt-2">
                            <div className="col-12">
                                <h4>{this.props.themeSectionData?.ColorATitle}</h4>
                            </div>
                            {this.props.themeSectionData?.ColorAOne !== "NotUsed"
                                &&
                                <div className="col-4 p-0 m-0">
                                    <label>{this.props.themeSectionData?.ColorAOneLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorAOne} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-a-one', "ColorAOne")} />
                                    <input type="checkbox" className="default-chkbox" />
                                </div>
                            }

                            {this.props.themeSectionData?.ColorATwo !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorATwoLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorATwo} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-a-two', "ColorATwo")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorAThree !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorAThreeLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorAThree} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-a-three', "ColorAThree")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorAFour !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorAFourLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorAFour} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-a-four', "ColorAFour")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }
                        </div>
                    }

                    {(this.props.themeSectionData?.ColorBOne !== "NotUsed" || this.props.themeSectionData?.ColorBTwo !== "NotUsed" || this.props.themeSectionData?.ColorBThree !== "NotUsed" || this.props.themeSectionData?.ColorBFour !== "NotUsed") &&

                        <div className="row p-2 pt-0 text-center mt-2">
                            <div className="col-12">
                                <h4>{this.props.themeSectionData?.ColorBTitle}</h4>
                            </div>
                            {this.props.themeSectionData?.ColorBOne !== "NotUsed"
                                &&
                                <div className="col-4 p-0 m-0">
                                    <label>{this.props.themeSectionData?.ColorBOneLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorBOne} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-b-one', "ColorBOne")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorBTwo !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorBTwoLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorBTwo} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-b-two', "ColorBTwo")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorBThree !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorBThreeLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorBThree} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-b-three', "ColorBThree")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorBFour !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorBFourLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorBFour} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-b-four', "ColorBFour")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }
                        </div>
                    }

                    {(this.props.themeSectionData?.ColorCOne !== "NotUsed" || this.props.themeSectionData?.ColorCTwo !== "NotUsed" || this.props.themeSectionData?.ColorCThree !== "NotUsed" || this.props.themeSectionData?.ColorCFour !== "NotUsed") &&

                        <div className="row p-2 pt-0 text-center mt-2">
                            <div className="col-12">
                                <h4>{this.props.themeSectionData?.ColorCTitle}</h4>
                            </div>
                            {this.props.themeSectionData?.ColorCOne !== "NotUsed"
                                &&
                                <div className="col-4 p-0 m-0">
                                    <label>{this.props.themeSectionData?.ColorCOneLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorCOne} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-c-one', "ColorCOne")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorCTwo !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorCTwoLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorCTwo} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-c-two', "ColorCTwo")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorCThree !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorCThreeLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorCThree} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-c-three', "ColorCThree")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }

                            {this.props.themeSectionData?.ColorCFour !== "NotUsed"
                                &&
                                <div className="col-4 p-0">
                                    <label>{this.props.themeSectionData?.ColorCFourLabel}</label>
                                    <br />
                                    <input type="color" className="m-0 p-0 input-color" value={this.props.themeSectionData.ColorCFour} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-color-c-four', "ColorCFour")} />
                                    <input type="checkbox" className="default-chkbox" />

                                </div>
                            }
                        </div>
                    }



                    <div className="row p-2 pt-0 text-center mt-4 mb-4">

                        {this.props.themeSectionData?.FontAFamily !== "NotUsed" &&
                            <>
                                <div className="col-12">
                                    <h4>{this.props.themeSectionData?.FontAFamilyLabel}   <input type="checkbox" className="default-chkbox" /></h4>
                                </div>
                                <div className="col-12 p-1 m-0">
                                    <Form.Select className="form-control p-1 drp-font-select" style={{ fontFamily: this.props.themeSectionData.FontAFamily, height: '35px', fontSize: '17px' }} aria-label="Default select example" size="sm" value={this.props.themeSectionData.FontAFamily} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-a-fontFamily', "FontAFamily")}>
                                        <option value="Gabriola" className="drp-font-family-1">Gabriola</option>
                                        <option value="Corbel" className="drp-font-family-2">Corbel</option>
                                        <option value="Comic Sans Ms" className="drp-font-family-3">Comic Sans Ms</option>
                                        <option value="Ink Free" className="drp-font-family-4">Ink Free</option>
                                        <option value="'Audiowide', sans-serif" className="drp-font-family-5">"Audiowide", sans-serif</option>
                                        <option value="MV Boli" className="drp-font-family-6">MV Boli</option>
                                        <option value="'Trirong', sans-serif" className="drp-font-family-7">'Trirong', sans-serif</option>
                                        <option value="monospace" className="drp-font-family-8">monospace</option>
                                        <option value="Montserrat" className="drp-font-family-9">Montserrat</option>
                                        <option value="Roboto" className="drp-font-family-10">Roboto</option>
                                        <option value="Bitter" className="drp-font-family-11">Bitter</option>
                                    </Form.Select>

                                </div>
                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="p-2 text-white">Font Size</h5>
                                        </div>
                                    <div className="col-12">

                                            {this.props.themeSectionData?.ListFontASize.length > 0 &&
                                                <>
                                                    <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontASize} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-a-fontSize', "FontASize")}>

                                                        {this.props.themeSectionData?.ListFontASize.map((val, idx) => {
                                                            return (
                                                                <>
                                                                    <option value={val}>{val}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-9 pe-0">
                                            <h5 className="p-2 text-white">Font Style</h5>
                                        </div>
                                        <div className="col-3 ps-0">
                                            <p className="p-0 mt-2 text-white">Bold</p>
                                        </div>
                                        <div className="col-9 pe-0">

                                            <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontAStyle} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-a-fontStyle', "FontAStyle")}>
                                                <option value="normal">Normal</option>
                                                <option value="oblique">Oblique</option>
                                                <option value="italic">Italic</option>
                                            </Form.Select>

                                        </div>
                                        <div className="col-3 ps-0">
                                            <input type="checkbox" className="cutomize-bold-checkbox" id="chkbold1" onChange={e => this.kitColorFontChange(e.target.checked, '--' + this.props.sectionName + '-a-fontBold', "FontABold")} checked={this.state.FontABold === "bold" ? true : false} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {this.props.themeSectionData?.FontBFamily !== "NotUsed" &&
                            <>
                                <div className="col-12 mt-2">
                                    <h4>{this.props.themeSectionData?.FontBFamilyLabel}   <input type="checkbox" className="default-chkbox" /></h4>
                                </div>
                                <div className="col-12 p-1">
                                    <Form.Select className="form-control p-1" style={{ fontFamily: this.props.themeSectionData.FontBFamily, height: '35px', fontSize: '17px' }} aria-label="Default select example" size="sm" value={this.props.themeSectionData.FontBFamily} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-b-fontFamily', "FontBFamily")}>
                                        <option value="Gabriola" className="drp-font-family-1">Gabriola</option>
                                        <option value="Corbel" className="drp-font-family-2">Corbel</option>
                                        <option value="Comic Sans Ms" className="drp-font-family-3">Comic Sans Ms</option>
                                        <option value="Ink Free" className="drp-font-family-4">Ink Free</option>
                                        <option value="'Audiowide', sans-serif" className="drp-font-family-5">"Audiowide", sans-serif</option>
                                        <option value="MV Boli" className="drp-font-family-6">MV Boli</option>
                                        <option value="'Trirong', sans-serif" className="drp-font-family-7">'Trirong', sans-serif</option>
                                        <option value="monospace" className="drp-font-family-8">monospace</option>
                                        <option value="Montserrat" className="drp-font-family-9">Montserrat</option>
                                        <option value="Roboto" className="drp-font-family-10">Roboto</option>
                                        <option value="Bitter" className="drp-font-family-11">Bitter</option>
                                    </Form.Select>
                                </div>

                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="p-2 text-white">Font Size</h5>
                                        </div>
                                        <div className="col-12">

                                            {this.props.themeSectionData?.ListFontBSize.length > 0 &&
                                                <>
                                                    <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontBSize} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-b-fontSize', "FontBSize")}>

                                                        {this.props.themeSectionData?.ListFontBSize.map((val, idx) => {
                                                            return (
                                                                <>
                                                                    <option value={val}>{val}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-9 pe-0">
                                            <h5 className="p-2 text-white">Font Style</h5>
                                        </div>
                                        <div className="col-3 ps-0">
                                            <p className="p-0 mt-2 text-white">Bold</p>
                                        </div>
                                        <div className="col-9 pe-0">

                                            <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontBStyle} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-b-fontStyle', "FontBStyle")}>
                                                <option value="normal">Normal</option>
                                                <option value="oblique">Oblique</option>
                                                <option value="italic">Italic</option>
                                            </Form.Select>

                                        </div>
                                        <div className="col-3 ps-0">
                                            <input type="checkbox" className="cutomize-bold-checkbox" id="chkbold2" onChange={e => this.kitColorFontChange(e.target.checked, '--' + this.props.sectionName + '-b-fontBold', "FontBBold")} checked={this.state.FontBBold === "bold" ? true : false} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {this.props.themeSectionData?.FontCFamily !== "NotUsed" &&
                            <>
                                <div className="col-12 mt-2">
                                    <h4>{this.props.themeSectionData?.FontCFamilyLabel}   <input type="checkbox" className="default-chkbox" /></h4>
                                </div>
                                <div className="col-12 p-1">
                                    <Form.Select className="form-control p-1" style={{ fontFamily: this.props.themeSectionData.FontCFamily, height: '35px', fontSize: '17px' }} aria-label="Default select example" size="sm" value={this.props.themeSectionData.FontCFamily} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-c-fontFamily', "FontCFamily")}>
                                        <option value="Gabriola" className="drp-font-family-1">Gabriola</option>
                                        <option value="Corbel" className="drp-font-family-2">Corbel</option>
                                        <option value="Comic Sans Ms" className="drp-font-family-3">Comic Sans Ms</option>
                                        <option value="Ink Free" className="drp-font-family-4">Ink Free</option>
                                        <option value="'Audiowide', sans-serif" className="drp-font-family-5">"Audiowide", sans-serif</option>
                                        <option value="MV Boli" className="drp-font-family-6">MV Boli</option>
                                        <option value="'Trirong', sans-serif" className="drp-font-family-7">'Trirong', sans-serif</option>
                                        <option value="monospace" className="drp-font-family-8">monospace</option>
                                        <option value="Montserrat" className="drp-font-family-9">Montserrat</option>
                                        <option value="Roboto" className="drp-font-family-10">Roboto</option>
                                        <option value="Bitter" className="drp-font-family-11">Bitter</option>
                                    </Form.Select>
                                </div>

                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <h5 className="p-2 text-white">Font Size</h5>
                                        </div>
                                        <div className="col-12">
                                            {this.props.themeSectionData?.ListFontCSize.length > 0 &&
                                                <>
                                                    <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontCSize} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-c-fontSize', "FontCSize")}>

                                                        {this.props.themeSectionData?.ListFontCSize.map((val, idx) => {
                                                            return (
                                                                <>
                                                                    <option value={val}>{val}</option>
                                                                </>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </>
                                            }
                                        </div>
                                    </div>                                </div>
                                <div className="col-6 p-1 m-0">
                                    <div className="row">
                                        <div className="col-9 pe-0">
                                            <h5 className="p-2 text-white">Font Style</h5>
                                        </div>
                                        <div className="col-3 ps-0">
                                            <p className="p-0 mt-2 text-white">Bold</p>
                                        </div>
                                        <div className="col-9 pe-0">

                                            <Form.Select className="form-control" size="sm" value={this.props.themeSectionData.FontCStyle} onChange={e => this.kitColorFontChange(e.target.value, '--' + this.props.sectionName + '-c-fontStyle', "FontCStyle")}>
                                                <option value="normal">Normal</option>
                                                <option value="oblique">Oblique</option>
                                                <option value="italic">Italic</option>
                                            </Form.Select>

                                        </div>
                                        <div className="col-3 ps-0">
                                            <input type="checkbox" className="cutomize-bold-checkbox" id="chkbold3" onChange={e => this.kitColorFontChange(e.target.checked, '--' + this.props.sectionName + '-c-fontBold', "FontCBold")} checked={this.state.FontCBold === "bold" ? true : false} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {this.props.themeSectionData?.MultipleImages.length > 0 &&
                            <>
                                <div className="col-12 mt-2">
                                    <h4>Slider Multiple Image</h4>
                                </div>
                                <div className="col-12 p-1">
                                    <input type="file" onChange={(e) => this.handleChangeMultipleImag(e)} style={{ color: '#FFFFFF' }} multiple />
                                </div>

                            </>
                        }
                        {this.props.themeSectionData?.BackgroundImage !== "NotUsed" &&
                            <>
                                <div className="col-12 mt-2">
                                    <h4>Background Image</h4>
                                </div>
                                <div className="col-12 p-1">
                                    <input type="file" onChange={(e) => this.handleChange(e)} style={{ color: '#FFFFFF' }} />
                                </div>
                                <div className="col-12 p-1">
                                    <label>( {this.state.BackgroundImageOpacity} )</label>
                                    <input
                                        id="typeinp"
                                        className="form-range"
                                        type="range"
                                        min="0" max="1"
                                        value={this.state.BackgroundImageOpacity}
                                        onChange={e => this.handleRangeChange(this, e)}
                                        step="0.05"
                                    />
                                </div>
                            </>
                        }



                    </div>

                </div >
                <div className="row mt-4">
                    <div className="col-md-12 text-center">
                        <button className="btn btn-warning mb-4 mt-4" onClick={e => this.kitGenerateJSON()}>Generate JSON</button>
                    </div>
                </div>

            </div >
        );
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding3,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        setThemeSettings: (landingPageSectionsBranding: any, otherval: any) => dispatch(weddingActionCreators.setThemeSettings(landingPageSectionsBranding, otherval))
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Customize);