import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}

export class Couple extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const couple = this.props.data;
        var branding = couple.LandingPageSectionBranding;
        document.body.style.setProperty('--CoupleData-color-a-one', branding.ColorAOne)
        document.body.style.setProperty('--CoupleData-color-a-two', branding.ColorATwo)
        document.body.style.setProperty('--CoupleData-color-a-three', branding.ColorAThree)
        document.body.style.setProperty('--CoupleData-color-b-one', branding.ColorBOne)
        document.body.style.setProperty('--CoupleData-color-b-two', branding.ColorBTwo)
        document.body.style.setProperty('--CoupleData-color-b-three', branding.ColorBThree)
        document.body.style.setProperty('--CoupleData-a-fontFamily', branding.FontAFamily)
        document.body.style.setProperty('--CoupleData-a-fontSize', branding.FontASize + "px")
        document.body.style.setProperty('--CoupleData-a-fontStyle', branding.FontAStyle)
        document.body.style.setProperty('--CoupleData-a-fontBold', branding.FontABold)
        document.body.style.setProperty('--CoupleData-b-fontFamily', branding.FontBFamily)
        document.body.style.setProperty('--CoupleData-b-fontSize', branding.FontBSize + "px")
        document.body.style.setProperty('--CoupleData-b-fontStyle', branding.FontBStyle)
        document.body.style.setProperty('--CoupleData-b-fontBold', branding.FontBBold)
        document.body.style.setProperty('--CoupleData-c-fontFamily', branding.FontCFamily)
        document.body.style.setProperty('--CoupleData-c-fontSize', branding.FontCSize + "px")
        document.body.style.setProperty('--CoupleData-c-fontStyle', branding.FontCStyle)
        document.body.style.setProperty('--CoupleData-c-fontBold', branding.FontCBold)

        return (
            <div id="story">
                <div className="container pb-4 mb-4">
                    <div className="">
                        <div className="container">
                            <h3 className="ast-h3">Happy Couple</h3>
                         
                            <p className="ast-p-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ultrices malesuada ante quis pharetra. Nullam non bibendum dolor. Ut vel turpis accumsan, efficitur dolor fermentum, tincidunt metus.</p>
                            <div className="row">
                                <div className="col-lg-1 col-md-1"></div>
                                <div className="col-lg-5 col-md-5 col-sm-12 ast-couple-left">
                                    <img className="ast-couple-img" src="http://demo.dethemes.com/forever/versions/top-bottom-bar/images/him2.jpg" />
                                    <h4 className="ast-h4">David Wiliam</h4>
                                    <p className="ast-p-content-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ultrices malesuada ante quis pharetra. Nullam non bibendum dolor. Ut vel turpis accumsan, efficitur dolor fermentum, tincidunt metus.</p>

                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-12 ast-couple-right">
                                    <img className="ast-couple-img" src="http://demo.dethemes.com/forever/versions/top-bottom-bar/images/her2.jpg" />
                                    <h4 className="ast-h4">Aliza Elizabeth</h4>
                                    <p className="ast-p-content-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ultrices malesuada ante quis pharetra. Nullam non bibendum dolor. Ut vel turpis accumsan, efficitur dolor fermentum, tincidunt metus.</p>

                                </div>
                                <div className="col-lg-1 col-md-1"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}