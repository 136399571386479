import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
import Database from '../../../../public/database/event/database.json'
import CountUp from 'react-countup'

export class Counter extends Component<{}, {}> {

    render() {
        return (
            <section className="event counter set-relative bg bg-img3 bg-about">
            <Container>
                <Row>
                    {
                        Database.CounterData.map((data, i) => {
                            return (
                                <Col md="3" xs="6" className="counter-container" key={i}>
                                    <div className="counters about">
                                        <img alt="" className="img-fluid counter-img" src={data.img} />
                                        <div className="counter-text">
                                            <h2 className="count-number text-white counts"><CountUp end={data.count} duration={10}/></h2>
                                            <h6 className="count-desc text-white">{data.desc}</h6>
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Container>
        </section>
        )
    }
}