import React, { Component } from 'react';
import Slider, { Settings } from 'react-slick';
import { Container, Row, Col } from 'reactstrap';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    name?: string;
    position?: string;
    desc?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Testimonial extends Component<IProps, {}> {
    SlickSettings: Settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        swipeToSlide: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    render() {
        const testimonial = this.props.data;
        if (testimonial !== undefined && testimonial !== null) {
            //Testimonial Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForTestimonialData = testimonial.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let TestimonialDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForTestimonialData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "", name: "", desc: "" };

                groupByMetaGroupForTestimonialData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Name") {
                        dt.name = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.desc = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Position") {
                        dt.position = data.MetaProperty.value;
                    }

                });
                TestimonialDataObj.push(dt);
            }
            return (
                <section className="wedding testimonial wishes-bg bg set-relative">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src="/assets/images/wedding-img/bottom.png" />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{testimonial.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>
                                            {testimonial.LandingPageSectionDescription}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="8" className="offset-lg-2">
                                <Slider {...this.SlickSettings}>
                                    {TestimonialDataObj.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <div className="testimonial-container">
                                                    <div className="border-around">
                                                        <img alt="" className="img-fluid m-auto"
                                                            src={item.img} />
                                                        <div className="text-center testimonial-info">
                                                            <p className="testimonial-para">
                                                                {item.desc}
                                                            </p>
                                                            <h4>{item.name}</h4>
                                                            <h6>C{item.position}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
        }
    }