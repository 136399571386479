import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import { fetchWeddingLP } from './state/weddingSlice';
import CenterLogoHeader from '../../common/center-logo-header';
import SectionSelector from './SectionSelector';

interface IProps extends PropsFromRedux {
};

class WeddingPage extends Component<IProps> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.props.getWeddingLP();

        document.body.style.setProperty('--primary', '#c0882f')
        document.body.style.setProperty('--secondary', '#595959')
        document.body.style.setProperty('--light', '#BF862D')
        document.body.style.setProperty('--dark', '#ECC878')
    }
    componentDidUpdate() {
        this.props.getWeddingLP;

        document.body.style.setProperty('--primary', '#c0882f')
        document.body.style.setProperty('--secondary', '#595959')
        document.body.style.setProperty('--light', '#BF862D')
        document.body.style.setProperty('--dark', '#ECC878')
    }

    render() {
        const initialValues = this.props.LandingPageDTO;

        return (
            <div>
                <CenterLogoHeader themeClass="wedding" />

                {initialValues.LandingPageSections.map((item, idx) => {
                    return (
                        <SectionSelector
                            sectionName={item.LandingPageSectionName}
                            key={idx + idx + idx}
                            data={item}
                        />
                    )
                })}

            </div>
        )
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getWeddingLP: () => dispatch(fetchWeddingLP())
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(WeddingPage);