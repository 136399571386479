import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading1?: string;
    heading2?: string;
    description?: string;
    address?: string;
    email?: string;
    mobile?: string;
    img?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Footer extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const footer = this.props.data;
        if (footer !== undefined && footer !== null) {

            var branding = footer.LandingPageSectionBranding;
            document.body.style.setProperty('--FooterData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--FooterData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--FooterData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--FooterData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--FooterData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--FooterData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--FooterData-color-b-three', branding.ColorBFour)
            document.body.style.setProperty('--FooterData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--FooterData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--FooterData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--FooterData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--FooterData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--FooterData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--FooterData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--FooterData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--FooterData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--FooterData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--FooterData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--FooterData-c-fontBold', branding.FontCBold)

            //Footer Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForFooterData = footer.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let footerDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForFooterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading1: "" };

                groupByMetaGroupForFooterData["" + i + ""].map((data: any) => {

                   
                    if (data.MetaPropertyType === "Description") {
                        dt.description = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                  
                });
                footerDataObj.push(dt);
            }
            return (
                <div id="footer">
                    <footer className="footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12 footerattr">
                                   
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 footerattr">
                                    <aside id="widget_logo-2" className="widget_widget_logo widget">
                                        <div className="tz_logo_widget">
                                            <a href="#">
                                                <img src={footerDataObj[0].img} style={{ width: "40%" }} alt="Everline" />
                                            </a>
                                            <p>{footerDataObj[0].description}</p>
                                        </div>
                                    </aside>
                                </div>

                                <div className="col-lg-4 col-md-12 col-sm-12 footerattr">
                                  
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            );
        }
    }
}