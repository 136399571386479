import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    name?: string;
    info?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Portfolio extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const portfolio = this.props.data;
        if (portfolio !== undefined && portfolio !== null) {

            var branding = portfolio.LandingPageSectionBranding;
            document.body.style.setProperty('--PortfolioData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--PortfolioData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--PortfolioData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--PortfolioData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--PortfolioData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--PortfolioData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--PortfolioData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--PortfolioData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--PortfolioData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--PortfolioData-b-fontStyle', branding.FontBStyle)

            //Portfolio Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForPortfolioData = portfolio.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let portfolioDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForPortfolioData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };

                groupByMetaGroupForPortfolioData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Name") {
                        dt.name = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Info") {
                        dt.info = data.MetaProperty.value;
                    }

                });
                portfolioDataObj.push(dt);
            }
            return (
                <div id="gallery">
                    <div className="row">
                        {portfolioDataObj.map((item, i) => {
                            return (
                                <div className="col-md-4 col-sm-4 px-0" key={i}>
                                    {/*<div className="box">*/}
                                    {/*    <img src={item.img} />*/}
                                    {/*    <div className="box-content">*/}
                                    {/*        <h3 className="title">{item.name}</h3>*/}
                                    {/*        <span className="post">{item.info}</span>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div id="container">
                                        <div className="wedp-container">
                                            <img src={item.img} alt="wed" className="image" />
                                            <div className="wedp-overlay">
                                                <div className="text"><span className="h">{item.info}</span></div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            );
        }
    }
}