import React, { useState } from 'react'
import Nav from './nav'
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap'

const Header = (props:any) => {
    const [show, setShow] = useState(false)
    const [sidebar, setSidebar] = useState(false);
    const [search, setSearch] = useState(false);

    const clickSidebar = () => {
        setSidebar(!sidebar)
        document.querySelector('.navbar')!.innerHTML = 'openSidebar';
    }

    return (
        <header className={`${props.className || 'app2'} loding-header nav-abs custom-scroll  `}>
            <Container>
                <Row>
                    <Col>
                        <nav>
                            <a className="m-r-auto" href="/">
                                {props.className == 'agency' ?
                                    <img alt="" className="img-fluid" src="/assets/images/logo/9.png" />
                                    :
                                    props.className == 'dark' ?
                                        <img alt="" className="img-fluid" src="/assets/images/logo/5.png" />
                                        :
                                        props.className == 'dark position-relative ecommerce' ?
                                            <img alt="" className="img-fluid" src="/assets/images/logo/5.png" />
                                            :
                                            props.className == 'dark bg-white ecommerce' ?
                                            <img alt="" className="img-fluid" src="/assets/images/logo/5.png" />
                                            :
                                                props.className == 'dark agency' ?
                                                    <img alt="" className="img-fluid" src="/assets/images/logo/5.png" />
                                                    :
                                                    props.className == 'saas1' ?
                                                        <img alt="" className="img-fluid" src="/assets/images/logo/3.png" />

                                                        : <img alt="" className="img-fluid" src="/assets/images/logo/1.png" />
                                }
                            </a>
                            <div className="responsive-btn">
                                <a className="toggle-nav" onClick={clickSidebar} >
                                    <i aria-hidden="true" className="fa fa-bars text-white"></i>
                                </a>
                            </div>
                            <Nav />
                        </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header
