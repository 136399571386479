import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    heading?: string;
    description?: string;
    button1?: string;
    button2?: string;
}
interface Branding {
    aboutColorFourth?: string;
    aboutFontFamilyPrimary?: string;
    aboutColorPrimaryFont?: string;
    aboutFontFamilyThird?: string;
    aboutColorSecondary?: string;
    aboutColorSecondaryDark?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class About extends React.Component<IProps, {}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const About = this.props.data;
        if (About !== undefined && About !== null) {

            //Branding Variables
            let br: Branding = { aboutColorSecondary: "" };
            //debugger;
            var branding = About.LandingPageSectionBranding;
            document.body.style.setProperty('--AboutData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--AboutData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--AboutData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--AboutData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--AboutData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--AboutData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--AboutData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--AboutData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--AboutData-color-c-four', branding.ColorCFour)
            document.body.style.setProperty('--AboutData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--AboutData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--AboutData-c-fontFamily', branding.FontCFamily)

            //Header Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForAboutData = About.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let AboutDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForAboutData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading: "" };

                groupByMetaGroupForAboutData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.description = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button1") {
                        dt.button1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button2") {
                        dt.button2 = data.MetaProperty.value;
                    }
                    
                });
                AboutDataObj.push(dt);                

            }
            return (
                <div>
                    <div className="homepage-info-section" id="about">
                        <div className="container">

                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-5">
                                    <figure>
                                        {branding.Image !== "NotUsed" && 
                                            <img src={branding.Image} alt="logo" />
                                        }
                                        {branding.Image === "NotUsed" &&
                                            <img src={AboutDataObj[0].img} alt="logo" />
                                        }
                                    </figure>
                                </div>

                                <div className="col-12 col-md-8 col-lg-7">
                                    <header className="entry-header">
                                        <h2 className="entry-title">{AboutDataObj[0].heading}</h2>
                                    </header>

                                    <div className="entry-content">
                                        <p>{AboutDataObj[0].description}</p>
                                    </div>

                                    <footer className="entry-footer">
                                        <a href="#" className="cn2-btn1">{AboutDataObj[0].button1}</a>
                                        <a href="#" className="cn2-btn2">{AboutDataObj[0].button2}</a>
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}