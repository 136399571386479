import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}

export class Counter extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const counter = this.props.data;
        var branding = counter.LandingPageSectionBranding;
        document.body.style.setProperty('--CounterData-color-a-one', branding.ColorAOne)
        document.body.style.setProperty('--CounterData-color-b-one', branding.ColorBOne)
        document.body.style.setProperty('--CounterData-color-b-two', branding.ColorBTwo)
        document.body.style.setProperty('--CounterData-a-fontFamily', branding.FontAFamily)
        document.body.style.setProperty('--CounterData-a-fontSize', branding.FontASize + "px")
        document.body.style.setProperty('--CounterData-a-fontStyle', branding.FontAStyle)
        document.body.style.setProperty('--CounterData-a-fontBold', branding.FontABold)
        return (
            <div id="counter">
                <div className="ast-countdown">
                    <div className="container p-4">
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-lg-8 col-md-12 col-sm-12">
                                <div className="row ast-count-row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="ast-count-circle">
                                            <span className="ast-count-data">00</span>
                                            <span className="ast-count-value">Days</span>
                                            <div className="ast-slide-animation"><div className="ast-count-animated-circle"></div></div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="ast-count-circle">
                                            <span className="ast-count-data">00</span>
                                            <span className="ast-count-value">Hours</span>
                                            <div className="ast-slide-animation"><div className="ast-count-animated-circle"></div></div>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="ast-count-circle">
                                            <span className="ast-count-data">00</span>
                                            <span className="ast-count-value">Minutes</span>
                                            <div className="ast-slide-animation"><div className="ast-count-animated-circle"></div></div>

                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="ast-count-circle">
                                            <span className="ast-count-data">00</span>
                                            <span className="ast-count-value">Seconds</span>
                                            <div className="ast-slide-animation"><div className="ast-count-animated-circle"></div></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}