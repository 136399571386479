import React from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
const defaultProps = {
    center: {
        lat: 40.7483441,
        lng: -73.9878147
    },
    zoom: 14
};
export const MyApp = () => (
    <Wrapper apiKey="AIzaSyCYF26lxe99k208avPDcppep6K8gxV5Nt8">
        <MyMapComponent center={defaultProps.center}
            zoom={defaultProps.zoom}  />
    </Wrapper>
);

function MyMapComponent({
    center,
    zoom,
}: {
    center: google.maps.LatLngLiteral;
    zoom: number;
}) {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (ref.current) {

            new window.google.maps.Map(ref.current, {
                center,
                zoom,
            });

            new window.google.maps.Marker();

        }
    });

    return <div ref={ref} id="map" style={{ width: '100%', height:'300px' } } />;
}


