import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class RootPage extends Component<{}, {}> {

    render() {
        return (
            <div className="container">
                <div className="row mt-4">
                    <div className="col-4">
                    </div>
                    <div className="col-4 text-center">
                     
                        <br />
                        <Link to="/wedding1">
                            <button className="btn btn-secondary mt-4">Wedding One</button>
                        </Link>
                        <br />
                        <Link to="/wedding2">
                            <button className="btn btn-danger mt-4">Wedding Two</button>
                        </Link>
                        <br />
                        <Link to="/wedding3">
                            <button className="btn btn-primary mt-4">Wedding Three</button>
                        </Link>
                        <br />
                        <Link to="/wedding">
                            <button className="btn btn-primary mt-4">Wedding Old</button>
                        </Link>
                        <br />
                        <Link to="/event">
                            <button className="btn btn-success mt-4">Event</button>
                        </Link>
                        <br />
                        <Link to="/conference1">
                            <button className="btn btn-warning mt-4">Conference One</button>
                        </Link>
                        <br />
                        <Link to="/conference2">
                            <button className="btn btn-primary mt-4">Conference Two</button>
                        </Link>
                    </div>
                    <div className="col-4">
                    </div>
                </div>
            </div>
        )
    }
}