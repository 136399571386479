import React, {Component} from 'react';
import Slider, {Settings} from 'react-slick'; 
import {Container,Row,Col} from 'reactstrap'
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    title?: string;
    date?: string;
    place?: string;
    desc?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Blog extends Component<IProps, {}> {
    SlickSettings: Settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 2,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: { slidesToShow: 1 }
            },
            {
                breakpoint: 1024,
                settings: { slidesToShow: 1 }
            }
        ]
    };
    render() {
        const blog = this.props.data;
        if (blog !== undefined && blog !== null) {
            //Blog Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForBlogData = blog.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let BlogDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForBlogData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "", title: "", desc: "" };

                groupByMetaGroupForBlogData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading") {
                        dt.title = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.desc = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Place") {
                        dt.place = data.MetaProperty.value;
                    }

                });
                BlogDataObj.push(dt);
            }

            return (
                <section className="wedding blog">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src="/assets/images/wedding-img/bottom.png" />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{blog.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>
                                            {blog.LandingPageSectionDescription}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid={true}>
                        <div></div>
                        <Row>
                            <Col xs="12">
                                <Slider className="owl-carousel owl-theme" {...this.SlickSettings}>
                                    {BlogDataObj.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <div className="blog-container">
                                                    <Row>
                                                        <Col md="6" sm="8" xs="12" className="offset-sm-2 offset-md-0">
                                                            <div className="set-skew">
                                                                <img alt="" className="img-fluid" src={item.img} />
                                                            </div>
                                                        </Col>
                                                        <Col md="6" sm="8" xs="12" className="offset-sm-2 offset-md-0">
                                                            <div className="center-text">
                                                                <div>
                                                                    <h3 className="blog-text gradient-text"> {item.title}</h3>
                                                                    <h6 className="blog-date">{item.date}</h6>
                                                                    <h6 className="blog-place">{item.place}</h6>
                                                                    <p className="blog-para">{item.desc}</p>
                                                                    <button className="btn btn-blog">read more</button>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}