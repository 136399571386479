import React from 'react';
import { Carousel } from 'react-bootstrap';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    heading1?: string;
    heading2?: string;
    date?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Slider extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const slider = this.props.data;
        if (slider !== undefined && slider !== null) {
            var branding = slider.LandingPageSectionBranding;
            document.body.style.setProperty('--SliderData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SliderData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--SliderData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--SliderData-color-a-four', branding.ColorAFour)
            document.body.style.setProperty('--SliderData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--SliderData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--SliderData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SliderData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--SliderData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--SliderData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--SliderData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--SliderData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--SliderData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--SliderData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--SliderData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--SliderData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--SliderData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--SliderData-c-fontBold', branding.FontCBold)
            //Slider Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForSliderData = slider.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let sliderDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForSliderData).length;
            var multiImg = 0;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };
                dt.img = slider.LandingPageSectionBranding.MultipleImages[multiImg];

                groupByMetaGroupForSliderData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading1") {
                        dt.heading1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading2") {
                        dt.heading2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }

                });
                sliderDataObj.push(dt);
                multiImg = multiImg + 1;

            }
            return (
                <div id="slider">

                    <div className='container-fluid px-0'>
                        <div className="row px-0 m-0">
                            <div className="col-12 px-0 m-0">

                                <Carousel indicators={false} fade interval={10000}>
                                    {sliderDataObj.map((item, i) => {
                                        return (
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={sliderDataObj[0].img}
                                            alt="First slide"
                                        />
                                        <Carousel.Caption className="ast-slide-content">

                                            <div className="ast-top-heading-border">
                                                <h4 className="ast-cap-top-heading">{sliderDataObj[0].heading1}</h4>
                                            </div>
                                            <h1 className="ast-h1">{sliderDataObj[0].heading2}</h1>
                                            <p className="ast-date py-2">{sliderDataObj[0].date}</p>

                                            <div className="ast-slide-animation"><div className="ast-slide-animated-circle"></div></div>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                        )
                                    })}

                                </Carousel>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}