import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';
import { MyApp } from "../../../common/GoogleMap";

interface Data {
    heading?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Map extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const map = this.props.data;
        if (map !== undefined && map !== null) {

            var branding = map.LandingPageSectionBranding;
            document.body.style.setProperty('--MapData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--MapData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--MapData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--MapData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--MapData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--MapData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--MapData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--MapData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--MapData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--MapData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--MapData-c-fontBold', branding.FontCBold)

            //Footer Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForFooterData = map.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let footerDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForFooterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading: "" };

                groupByMetaGroupForFooterData["" + i + ""].map((data: any) => {


                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }


                });
                footerDataObj.push(dt);
            }
            return (
                <div id="map">
                    <div className="container-fluid map-section">
                        <div className="row">
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-3 col-sm-12 text-center">
                                <div className="content-area">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="act-map-address"> <i className="map-icon fa fa-map-marker"></i>
                                                Address </h3>

                                        </div>
                                        <div className="col-12">
                                            <p className="address-text">Martinique New York on Broadway, Curio Collection by Hilton, 49 W 32nd St, New York, NY 10001</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1">
                            </div>
                            <div className="col-md-7 ps-0 pe-0 col-sm-12">
                                <MyApp />
                            </div>
                        </div>
                    </div>


                </div>
            );
        }
    }
}