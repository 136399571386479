import React from 'react';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading1?: string;
    date?: string;
    address?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class SaveTheDate extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const counter = this.props.data;
        if (counter !== undefined && counter !== null) {

            var branding = counter.LandingPageSectionBranding;
            document.body.style.setProperty('--SaveTheDateData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SaveTheDateData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--SaveTheDateData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--SaveTheDateData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--SaveTheDateData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SaveTheDateData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--SaveTheDateData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--SaveTheDateData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--SaveTheDateData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--SaveTheDateData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--SaveTheDateData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--SaveTheDateData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--SaveTheDateData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--SaveTheDateData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--SaveTheDateData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--SaveTheDateData-c-fontBold', branding.FontCBold)

            //Counter Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForCounterData = counter.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let counterDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForCounterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading1: "" };

                groupByMetaGroupForCounterData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading1") {
                        dt.heading1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Address") {
                        dt.address = data.MetaProperty.value;
                    }

                });
                counterDataObj.push(dt);
            }
            return (
                <div id="savethedate">
                    <div className="std">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                                    <div className="row">
                                        <div className="col-2">
                                            <i className="std-calendar-icon fa fa-calendar"></i>
                                        </div>
                                        <div className="col-10">
                                            <h3 className="act-std-text">{counterDataObj[0].heading1}</h3>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                          
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 p-2 text-center">
                                            <p className="std-date">{counterDataObj[0].date}</p>
                                            <br />
                                            <p className="std-address">{counterDataObj[0].address}</p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}