import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';

export class Sponsor extends Component<{}, {}> {

    render() {
        return (
            <section className="event sponsor about set-relative bg bg-img2 bg-about">
            <Container className="set-margin about">
                <Row>
                    <Col md="10" className="offset-md-1">
                        <div className="title title3">
                            <div className="main-title">
                                <h2 className=" text-white mt-1">sponsor</h2>
                            </div>
                            <div className="sub-title">
                                <p className="text-white">Register now and reserve your seat for this
                                    <span>year's Unice,</span> the largest <span>web development</span> and online marketing
                                    <span>conference in UK,</span> covering everything you need to know in order to develop a
                                    successful online business.</p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/1.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/2.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/3.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/4.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/5.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/6.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/7.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/8.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/9.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/10.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/11.png" /></a>
                        </div>
                    </Col>
                    <Col md="3" sm="4" xs="6" className="sponsor-img">
                        <div className="text-center">
                            <a href="#"><img alt="" className="img-fluid" src="/assets/images/event/sponsor/12.png" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        )
    }
}