import React, { Component } from 'react';
import { NavbarHeader } from './components/NavbarHeader';
import { Counter } from './components/Counter';
import { SaveTheDate } from './components/SaveTheDate';
import { Slider } from './components/Slider';
import { Blog } from './components/Blog';
import { Portfolio } from './components/Portfolio';
import { WeddingParty } from './components/WeddingParty';
import { Story } from './components/Story';
import { Schedule } from './components/Schedule';
import { Social } from './components/Social';
import { Map } from './components/Map';
import { Footer } from './components/Footer';

interface IProps {
    sectionName: string; // section
    data?: any;
}

class SectionSelector extends Component<IProps> {

    components = {
        NavData: NavbarHeader,
        SliderData: Slider,
        PortfolioData: Portfolio,
        WeddingPartyData: WeddingParty,
        StoryData: Story,
        ScheduleData: Schedule,
        BlogData: Blog,
        CounterData: Counter,
        SaveTheDateData: SaveTheDate,
        FooterData: Footer,
        SocialData: Social,
        MapData: Map,
    };

    render() {
        const TagName = (this.components as any)[this.props.sectionName || 'empty'];
        return <TagName
            data={this.props.data}
        />
    }
}

export default SectionSelector;