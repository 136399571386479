import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../public/assets/wedding2/scss/style.scss';
import { weddingActionCreators } from './state/weddingSlice'
import SectionSelector from './SectionSelector';
import ThemeSettings from './components/branding/ThemeSettings';

interface IProps extends PropsFromRedux {
};

class Wedding1 extends Component<IProps> {
    state = {
        sliderOpen: false,
        sectionID: 0,
    }
    constructor(props: any) {
        super(props);
        this.state = {
            sliderOpen: false,
            sectionID: 0
        }
        this.EditClick = this.EditClick.bind(this);
    }
    componentDidMount() {
        this.props.getWedding1LP();
    }

    EditClick = (id: any) => {
        this.props.setThemeSettings(id, true)
    }

    render() {
        const initialValues = this.props.LandingPageDTO;

        return (
            <>
                <ThemeSettings />

                <div className="act-Wed-1">
                    {initialValues.LandingPageSections.map((item, idx) => {
                        return (
                            <div className="section-edit-border" key={idx} >
                                <button onClick={() => this.EditClick(item.LandingPageSectionId)} className="section-edit-button"><i className="fa fa-pencil" /> EDIT</button>
                                <SectionSelector
                                    sectionName={item.LandingPageSectionName}
                                    key={idx + idx + idx}
                                    data={item}
                                />
                            </div>
                        )
                    })}

                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding1,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getWedding1LP: () => dispatch(weddingActionCreators.fetchWedding1LP()),
        setThemeSettings: (id: number, isOpen: boolean) => dispatch(weddingActionCreators.selectThemeSettings(id, isOpen))

    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Wedding1);