import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading?: string;
    button?: string;
    date?: string;
    address?: string;
}

interface Branding {
    headerColorPrimaryFont?: string;
    headerFontFamilySecondary?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Slider extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const Header = this.props.data;
        if (Header !== undefined && Header !== null) {
            //Branding Variables
            let br: Branding = { headerColorPrimaryFont: "" };
            //debugger;
            var branding = Header.LandingPageSectionBranding;
            document.body.style.setProperty('--HeaderData-bg-img', 'url(' + branding.BackgroundImage + ')')
            document.body.style.setProperty('--HeaderData-bg-img-opacity', branding.BackgroundImageOpacity)
            document.body.style.setProperty('--HeaderData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--HeaderData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--HeaderData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--HeaderData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--HeaderData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--HeaderData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--HeaderData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--HeaderData-b-fontStyle', branding.FontBStyle)

            //Header Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForHeaderData = Header.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let SliderDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForHeaderData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading: "" };

                groupByMetaGroupForHeaderData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.button = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Address") {
                        dt.address = data.MetaProperty.value;
                    }

                });
                SliderDataObj.push(dt);

            }
        return (
            <div id="slider">

                <div className='container-fluid px-0'>
                    <div className="row px-0 m-0">
                        <div className="col-12 px-0 m-0">
                           
                            <section id="cnst-intro">
                                <div className="cnst-intro-container wow fadeIn">
                                    <h1 className="mb-4 pb-0">{SliderDataObj[0].heading}</h1>
                                    <p className="mb-4 pb-0">{SliderDataObj[0].date}, {SliderDataObj[0].address}</p>
                                </div>
                            </section>     
                            
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    }
}