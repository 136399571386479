import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import { fetchEventLP } from './state//eventSlice';

import Header from '../../common/header';
import { Banner } from './components/Banner';
import { Booking } from './components/Booking';
import { About } from './components/About';
import { Speaker } from './components/Speaker';
import { Sponsor } from './components/Sponsor';
import { Schedule } from './components/Schedule';
import { Testimonial } from './components/Testimonial';
import { Gallery } from './components/Gallery';
import { Counter } from './components/Counter';
import { Subscribe } from './components/Subscribe';
import { Pricing } from './components/Pricing';
import { Blog } from './components/Blog';
import { Footer } from './components/Footer';

interface Data {
    img: string;
    title: string;
    name: string;
    role: string;
    details: string;
    timing: string;
    user: string;
    features: [];
    price: number;
    date: string;
    place: string;
    desc: string;
    count: number;
}

interface LandingPageSection {
    LandingPageSectionName: string;
    Data: Data[];
}

interface LandingPage {
    LandingPageSections: LandingPageSection[];
}

interface IProps extends PropsFromRedux {
};

export class EventPage extends Component<IProps> {
    constructor(props: any) {
        super(props);
        //this.state = {
        //   // EventLPData: this.props.EventLPData,
        //}
    }
    componentDidMount() {
        this.props.getEventLP;

        document.body.style.setProperty('--primary', '#e3154f')
        document.body.style.setProperty('--secondary', '#321575')
        document.body.style.setProperty('--light', '#321575')
        document.body.style.setProperty('--dark', '#e3154f')
    }
    componentDidUpdate() {
        this.props.getEventLP;               
        document.body.style.setProperty('--primary', '#e3154f')
        document.body.style.setProperty('--secondary', '#321575')
        document.body.style.setProperty('--light', '#321575')
        document.body.style.setProperty('--dark', '#e3154f')
    }

    render() {

        return (
            <div>
                <Header className="event nav-lg" />
                <Banner />
                <Booking />
                <About />
                <Speaker />
                <Sponsor />
                <Schedule />
                <Testimonial />
                <Gallery />
                <Counter />
                <Subscribe />
                <Pricing />
                <Blog />
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getEventLP: () => dispatch(fetchEventLP)
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(EventPage);