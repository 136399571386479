import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}
export class WeddingParty extends React.Component<IProps, {}> {
    state = {
        selectedTab: 1,
    }
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);

    }

    handleClick(itemIndex: number) {
        this.setState({
            selectedTab: itemIndex
        });
    };

    render() {
        const Agenda = this.props.data;
        if (Agenda !== undefined && Agenda !== null) {

            //Branding Variables
            var branding = Agenda.LandingPageSectionBranding;

            document.body.style.setProperty('--WeddingPartyData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--WeddingPartyData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--WeddingPartyData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--WeddingPartyData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--WeddingPartyData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--WeddingPartyData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--WeddingPartyData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--WeddingPartyData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--WeddingPartyData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--WeddingPartyData-color-c-four', branding.ColorCFour)
            document.body.style.setProperty('--WeddingPartyData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--WeddingPartyData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--WeddingPartyData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--WeddingPartyData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--WeddingPartyData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--WeddingPartyData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--WeddingPartyData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--WeddingPartyData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--WeddingPartyData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--WeddingPartyData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--WeddingPartyData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--WeddingPartyData-c-fontBold', branding.FontCBold)
            return (
                <div id="weddingparty">
                    <div className="cn2-wedp">

                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4 className="wedp-title">The Wedding Party</h4>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row wedp-btns">
                                <div className="col-12 text-center">
                                    <button onClick={this.handleClick.bind(this, 1)} className={this.state.selectedTab === 1 ? "wedp-btn mt-1 active" : "wedp-btn mt-1"}>Bridesmaids</button>
                                    <button onClick={this.handleClick.bind(this, 2)} className={this.state.selectedTab === 2 ? "wedp-btn mt-1 active" : "wedp-btn mt-1"}>Groomsmen</button>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {this.state.selectedTab === 1
                                        &&
                                        <div className="row wedp-mb">

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker1.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Kriti
                                                        <br />
                                                        Best Friend
                                                       
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker2.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Male Vign
                                                        <br />
                                                        Maid of Honor

                                                    </div>
                                                </div>
                                              
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker3.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Holly
                                                        <br />
                                                        Friend

                                                    </div>
                                                </div>
                                            
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker4.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Brodd
                                                        <br />
                                                        Friend

                                                    </div>
                                                </div>
                                              
                                            </div>

                                          

                                        </div>
                                    }

                                    {this.state.selectedTab === 2
                                        &&
                                        <div className="row">

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker5.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Jhon
                                                        <br />
                                                        Best Friend

                                                    </div>
                                                </div>
                                             
                                            </div>

                                            <div className="col-lg-4 col-md-6">
                                                <div className="img-wrapper-detail">
                                                    <span className="img-wedp">
                                                        <img src="assets/conference2/imgs/speaker6.png" alt="" />
                                                    </span>
                                                    <div className="details-wedp">
                                                        Kaiste
                                                        <br />
                                                        Friend

                                                    </div>
                                                </div>
                                            
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
