import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import { weddingActionCreators } from './state/weddingSlice'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../public/assets/wedding2.2/scss/style.scss';
import SectionSelector from './SectionSelector';
import ThemeSettings from './components/branding/ThemeSettings';

interface IProps extends PropsFromRedux {
};

class Wedding3 extends Component<IProps> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.getWedding3LP();

    }
    EditClick = (id: any) => {
        this.props.setThemeSettings(id, true)
    }
    render() {
        const initialValues = this.props.LandingPageDTO;

        return (
            <>
                <ThemeSettings />

                <div className="act-Wed-3">
                    {initialValues.LandingPageSections.map((item, idx) => {
                        return (
                            <div className="section-edit-border">
                                <button onClick={() => this.EditClick(item.LandingPageSectionId)} className="section-edit-button"><i className="fa fa-pencil" /> EDIT</button>

                                <SectionSelector
                                    sectionName={item.LandingPageSectionName}
                                    key={idx + idx + idx}
                                    data={item}
                                />
                            </div>
                        )
                    })}
                </div>
            </>
        );
    }
}


const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding3,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getWedding3LP: () => dispatch(weddingActionCreators.fetchWedding3LP()),
        setThemeSettings: (id: number, isOpen: boolean) => dispatch(weddingActionCreators.selectThemeSettings(id, isOpen))
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Wedding3)