import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}
export class Schedule extends React.Component<IProps, {}> {
    state = {
        selectedTab: 1,
    }
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);

    }

    handleClick(itemIndex: number) {
        this.setState({
            selectedTab: itemIndex
        });
    };

    render() {
        const Agenda = this.props.data;
        if (Agenda !== undefined && Agenda !== null) {

            //Branding Variables
            var branding = Agenda.LandingPageSectionBranding;

            document.body.style.setProperty('--AgendaData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--AgendaData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--AgendaData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--AgendaData-color-a-four', branding.ColorAFour)
            document.body.style.setProperty('--AgendaData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--AgendaData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--AgendaData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--AgendaData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--AgendaData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--AgendaData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--AgendaData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--AgendaData-color-c-four', branding.ColorCFour)
            document.body.style.setProperty('--AgendaData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--AgendaData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--AgendaData-c-fontFamily', branding.FontCFamily)
            return (
                <div>
                    <section className="cn2-schedule">

                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h4 className="agenda-title">Agenda</h4>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row agenda-btns">
                                <div className="col-12 text-center">
                                    <button onClick={this.handleClick.bind(this, 1)} className={this.state.selectedTab === 1 ? "agenda-btn active" : "agenda-btn"}>Day 1</button>
                                    <button onClick={this.handleClick.bind(this, 2)} className={this.state.selectedTab === 2 ? "agenda-btn active" : "agenda-btn"}>Day 2</button>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-12 day-box">
                                    {this.state.selectedTab === 1
                                        &&
                                        <div className="row">

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Track 1</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">9 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>10 AM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker1.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Nikah Ceremoney</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod..</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">10:30 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>12 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker2.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">2:30 PM</p>
                                                                                    <p>-</p>
                                                                                    <p>4 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker3.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Track 2 Name</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">9 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>10 AM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker4.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Lunch</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod..</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">2:30 PM</p>
                                                                                    <p>-</p>
                                                                                    <p>4 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker1.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Track 3</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">10:30 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>12 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker6.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    }

                                    {this.state.selectedTab === 2
                                        &&
                                        <div className="row">

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Day2 Track1</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">


                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">10:30 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>12 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker6.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">9 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>10 AM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker4.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Nikah Ceremoney</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod..</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">2:30 PM</p>
                                                                                    <p>-</p>
                                                                                    <p>4 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker5.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Track Name</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">2:30 PM</p>
                                                                                    <p>-</p>
                                                                                    <p>4 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker1.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">9 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>10 AM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker2.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Lunch</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod..</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <h3 className='track-name'>Day2 Track N</h3>
                                                    </div>
                                                </div>
                                                <div className="track-box">

                                                    <div className="row">

                                                        <div className="col-12">

                                                            <div className="session-box">

                                                                <div className="row">
                                                                    <div className="col-12">

                                                                        <div className="row">
                                                                            <div className="col-3 pe-0">
                                                                                <div className="session-time text-center">
                                                                                    <p className="mt-2">10:30 AM</p>
                                                                                    <p>-</p>
                                                                                    <p>12 PM</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-9 ps-0">

                                                                                <div className="session-details">

                                                                                    <div className="row">
                                                                                        <div className="col-4">

                                                                                            <img className='session-speaker' src='assets/conference2/imgs/speaker2.png' />

                                                                                        </div>

                                                                                        <div className="col-8">
                                                                                            <h5>Title</h5>
                                                                                            <p>Lorem ipsum dolor sit amet, sed do eiusmod</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                </div>
            );
        }
    }
}
