import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getList } from '../../../common/genericApi';
import { AppThunk, RootState } from '../../../store/store';
import { LandingPageDTO, LandingPageSection, LandingPageType } from '../../../server/LandingPageModel';


export interface WeddingLPState {
    EventLPData: LandingPageDTO;
}

const initialState: WeddingLPState = {
    EventLPData: {} as LandingPageDTO
}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        getEventLandingPageSuccess(state, action: PayloadAction<LandingPageDTO>) {
            state.EventLPData = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { getEventLandingPageSuccess } = eventSlice.actions

export default eventSlice.reducer

export const fetchEventLP = (): AppThunk => async dispatch => {
    try {
        //debugger;
        const Result = await getList<LandingPageDTO>('api/LandingPages/Event')
        dispatch(getEventLandingPageSuccess(Result))
    } catch (err: any) {
        //dispatch(getSiteSettingsFailure(err))
    }
}