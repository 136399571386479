import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Social extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const social = this.props.data;
        if (social !== undefined && social !== null) {

            var branding = social.LandingPageSectionBranding;
            document.body.style.setProperty('--SocialData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SocialData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--SocialData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--SocialData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--SocialData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SocialData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--SocialData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--SocialData-a-fontBold', branding.FontABold)

            //Footer Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForFooterData = social.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let footerDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForFooterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading: "" };

                groupByMetaGroupForFooterData["" + i + ""].map((data: any) => {


                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }


                });
                footerDataObj.push(dt);
            }
            return (
                <>
              

                    <div id="social">
                        <footer className="wed-social">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 col-sm-12 footerattr">

                                    </div>
                                    <div className="col-lg-4 col-md-12 col-sm-12 footerattr">
                                        <h3 className="social-title text-center"><span>{footerDataObj[0].heading}</span></h3>
                                        <div className="tz_social">
                                            <a href="#" className="fa fa-facebook"></a>
                                            <a href="#" className="fa fa-google-plus"></a>
                                            <a href="#" className="fa fa-twitter"></a>
                                            <a href="#" className="fa fa-youtube"></a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12 col-sm-12 footerattr">

                                    </div>
                                </div>
                            </div>
                        </footer>
                    </div>
                </>
            );
        }
    }
}