import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class Speaker extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="speaker">
                <section id="speakers" className="our-speaker-area bg-img bg-gradient-overlay section-padding-100-60">
                    <div className="container">
                        <div className="row mt-4">

                            <div className="col-12">
                                <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                                    <p className="cnst-speaker-p">Our Speakings</p>
                                    <h4 className="cnst-h3">Conference Speakers</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row" id="speakers-details">
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/5.jpg" alt="Speaker 1" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Brenden Legros</a></h3>
                                        <p>Quas alias incidunt</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/6.jpg" alt="Speaker 2" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Hubert Hirthe</a></h3>
                                        <p>Consequuntur odio aut</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/8.jpg" alt="Speaker 3" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Cole Emmerich</a></h3>
                                        <p>Fugiat laborum et</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/3.jpg" alt="Speaker 4" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Jack Christiansen</a></h3>
                                        <p>Debitis iure vero</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/7.jpg" alt="Speaker 5" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Alejandrin Littel</a></h3>
                                        <p>Qui molestiae natus</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="speaker">
                                    <img src="	https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/09/2.jpg" alt="Speaker 6" className="img-fluid" />
                                    <div className="details">
                                        <h3><a href="#">Willow Trantow</a></h3>
                                        <p>Non autem dicta</p>
                                        <div className="social">
                                            <a href=""><i className="fa fa-twitter"></i></a>
                                            <a href=""><i className="fa fa-facebook"></i></a>
                                            <a href=""><i className="fa fa-google-plus"></i></a>
                                            <a href=""><i className="fa fa-linkedin"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="more-speaker-btn text-center mt-20 mb-40">
                                    <a className="confer-btn-white" href="#">view all Speaker <i className="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        );
    }
}