import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class Footer extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="contact">
                <section className="contact-our-area section-padding-100-0">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="section-heading-2 text-center wow fadeInUp" data-wow-delay="300ms">
                                    <p className="cnst-speaker-p">Have Question?</p>
                                    <h4 className="cnst-h3">Contact us</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-between cnst-contact-row">
                            <div className="col-12 col-sm-3">
                                <div className="contact-information mb-100">

                                    <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                                        <p>Address:</p>
                                        <h6>184 Main Collins Street</h6>
                                    </div>

                                    <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                                        <p>Phone:</p>
                                        <h6>(226) 446 9371</h6>
                                    </div>

                                    <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                                        <p>Email:</p>
                                        <h6>confer@gmail.com</h6>
                                    </div>

                                    <div className="single-contact-info wow fadeInUp" data-wow-delay="300ms">
                                        <p>Website:</p>
                                        <h6>www.confer.com</h6>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-sm-8">

                                <div className="contact_from_area mb-100 clearfix wow fadeInUp" data-wow-delay="300ms">
                                    <div className="contact_form">
                                        <form action="#" method="post" id="main_contact_form">
                                            <div className="contact_input_area">
                                                <div id="success_fail_info"></div>
                                                <div className="row">

                                                    <div className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control mb-30" name="name" id="name" placeholder="Your Name" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control mb-30" name="name" id="name2" placeholder="Last Name" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control mb-30" name="email" id="email" placeholder="E-mail" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control mb-30" name="subject" id="subject" placeholder="Your Number" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <textarea name="message" className="form-control mb-30" id="message" cols={30} rows={6} placeholder="Your Message *"></textarea>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <a type="submit" className="confer-btn">Send Message <i className="fa fa-long-arrow-right"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="footer-area bg-img bg-overlay-2 pb-0">
                    <div className="main-footer-area">
                        <div className="container">
                            <div className="row text-center">

                                <div className="col-12 col-sm-6 col-lg-3">
                                    <div className="single-footer-widget mb-60" data-wow-delay="300ms">

                                        <h5 className="widget-title">Conferen</h5>
                                        <div className="footer-contact-info">

                                            <p>To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain.</p>

                                            <div className="social-info pt-4">
                                                <a href="#"><i className="fa fa-facebook"></i></a>
                                                <a href="#"><i className="fa fa-instagram"></i></a>
                                                <a href="#"><i className="fa fa-twitter"></i></a>
                                                <a href="#"><i className="fa fa-linkedin"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-lg-3">
                                    <div className="single-footer-widget mb-60" data-wow-delay="300ms">
                                        <h5 className="widget-title">Contact</h5>

                                        <div className="footer-contact-info">
                                            <p><i className="fa fa-map"></i> 184 Main Lahore Street</p>
                                            <p><i className="fa fa-phone"></i> (111) 226 3471</p>
                                            <p><i className="fa fa-mail"></i> EventDone@gmail.com</p>
                                            <p><i className="fa fa-globe"></i> www.eventdone.com</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-lg-3">
                                    <div className="single-footer-widget mb-60" data-wow-delay="300ms">
                                        <h5 className="widget-title">Contact</h5>

                                        <div className="footer-contact-info">
                                            <p><i className="fa fa-map"></i> 184 Main Lahore Street</p>
                                            <p><i className="fa fa-phone"></i> (111) 226 3471</p>
                                            <p><i className="fa fa-mail"></i> EventDone@gmail.com</p>
                                            <p><i className="fa fa-globe"></i> www.eventdone.com</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-12 col-sm-6 col-lg-3">
                                    <div className="single-footer-widget mb-60" data-wow-delay="300ms">
                                        <h5 className="widget-title">Contact</h5>

                                        <div className="footer-contact-info">
                                            <p><i className="fa fa-map"></i> 184 Main Lahore Street</p>
                                            <p><i className="fa fa-phone"></i> (111) 226 3471</p>
                                            <p><i className="fa fa-mail"></i> EventDone@gmail.com</p>
                                            <p><i className="fa fa-globe"></i> www.eventdone.com</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="container pt-4">
                        <div className="copywrite-content">
                            <div className="row">

                                <div className="col-12 col-md-12 col-sm-12">
                                    <div className="copywrite-text">
                                        <p>
                                            Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="#" target="_blank">Event Done</a>.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </footer>

            </div>
        );
    }
}