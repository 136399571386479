import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Database from '../../../../../public/database/settings/database.json'
import { LandingPageSectionBranding } from '../../../../server/LandingPageModel';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Kit from './Kit';
import Customize from './Customize';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../../../store/store';
import { conferenceActionCreators } from '../../state/conferenceSlice';

interface IProps extends PropsFromRedux {
};


interface IState {
    isOpen: string,
    activeID: number,
    customizeBtn: string,
    customizeSec: string
}


export class ThemeSettings extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: "NotUsed",
            activeID: 1,
            customizeBtn: "",
            customizeSec: ""
        }
    }

    componentDidMount() {
        this.props.getBrandingKits();
    }

    onChange(id: number) {

        if (typeof (this.state.activeID) !== undefined) {
            if (id !== this.state.activeID)
                this.setState({
                    activeID: id
                });
        }
        else {
            this.setState({
                activeID: id
            });
        }

        this.props.TemplateBrandingKit.BrandingKits.map(item => {
            if (item.BrandingKitID === id) {
                item.BrandingKitLandingPageSections.map(i => {
                    // debugger;
                    var idx = item.BrandingKitLandingPageSections.findIndex(k => k.LandingPageSectionId == i.LandingPageSectionId)

                    var t = this.props.LandingPageDTO.LandingPageSections[idx].LandingPageSectionBranding
                    var u = i.LandingPageSectionBranding;
                    var c = { ...t, u }

                    this.props.changeThemeKit(u, idx)
                })
            }
        })

    }

    kitChangeCustomize = () => {
        this.setState({
            customizeBtn: "cus-inactive"
        });

        this.setState({
            customizeSec: "cus-active"
        });
    }
    kitChangeCustomizeClose = () => {
        this.setState({
            customizeSec: "cus-inactive"
        });

        this.setState({
            customizeBtn: "cus-active"
        });
    }

    kitColorFontChange = (value: any, item: any, stateName: any) => {

        document.body.style.setProperty(item, value)

        //this.setState({
        //    [stateName]: value
        //});

    }

    kitGenerateJSON = () => {

        console.log("Customized JSON of Kit", JSON.stringify(this.state));

    }

    selectTab(k: string | null) {

        k && this.props.setTab(k)
    }

    toggle(isOpen: string) {
        if (isOpen === "NotUsed") {
            this.props.toggleBrandingSlider(false, 'kit');

        } else if (isOpen == "Open") {
            this.props.toggleBrandingSlider(true, '');
            this.props.setTab('kit');
        }
    }

    render() {
        const bkit = this.props.TemplateBrandingKit.BrandingKits;

        //let sliderShow = false;
        //if (this.state.isOpen === "Open") {
        //    sliderShow = true;
        //}
        //else {
        //    sliderShow = this.props.sliderIsOpen;
        //}

        const sectionData = this.props.LandingPageDTO.LandingPageSections.find(f => f.LandingPageSectionId == this.props.sectionId);

        return (
            <div key={this.props.sectionId}>
                <div className="sidenav">
                    <span onClick={() => this.toggle("Open")} className="themesettings"><i className="fa fa-gear fa-spin"></i> </span>
                </div>
                <Offcanvas show={this.props.sliderIsOpen} onHide={() => this.toggle("NotUsed")} scroll={true} backdrop={false} restoreFocus={true} className="cn2-themesettings-convax">
                    <Offcanvas.Header closeButton className="" closeVariant="white">
                        <Offcanvas.Title style={{ color: "white" }}>Settings</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <h4 className="cn2-p"> Branding Kits</h4>

                        <br />

                        <div className="row mb-4 pb-4 set-tabs">
                            <Tabs
                                activeKey={this.props.sliderSelectedTab}
                                onSelect={(k) => this.selectTab(k)}
                                id="justify-tab-example"
                                className="mb-3"
                                justify
                            >
                                <Tab eventKey="kit" title="Kits" className="">
                                    <div className="row mb-4 pb-4">
                                        {bkit
                                            &&
                                            bkit.map(item => {

                                                return (
                                                    <Kit data={item} onChange={this.onChange.bind(this)} selectedId={this.state.activeID} />
                                                )

                                            })}

                                    </div>
                                </Tab>

                                <Tab eventKey="cust" title="Customize">
                                    {sectionData
                                        &&
                                        <Customize themeSectionData={sectionData.LandingPageSectionBranding} sectionName={sectionData?.LandingPageSectionName} />
                                    }

                                </Tab>
                            </Tabs>

                        </div>

                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        );
    }
}


const mapStateToProps = (state: AppState.RootState) => ({
    ...state.conference,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBrandingKits: () => dispatch(conferenceActionCreators.fetchBrandingKits()),
        getConferenceLP: () => dispatch(conferenceActionCreators.fetchConferenceLP()),
        changeThemeKit: (landingPageSectionsBranding: any, otherval: any) => dispatch(conferenceActionCreators.setThemeSettings(landingPageSectionsBranding, otherval)),
        setThemeSettings: (id: number, isOpen: boolean) => dispatch(conferenceActionCreators.selectThemeSettings(id, isOpen)),
        toggleBrandingSlider: (isOpen: boolean, tabEvent: string) => dispatch(conferenceActionCreators.closeBrandingSlider(isOpen, tabEvent)),
        setTab: (tabEvent: string) => dispatch(conferenceActionCreators.setTab(tabEvent)),
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ThemeSettings);