import React from 'react';
import { Carousel } from 'react-bootstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    heading?: string;
    description?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Slider extends React.Component<IProps, {}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const slider = this.props.data;
        if (slider !== undefined && slider !== null) {
            var branding = slider.LandingPageSectionBranding;
            document.body.style.setProperty('--SliderData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SliderData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--SliderData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--SliderData-color-a-four', branding.ColorAFour)
            document.body.style.setProperty('--SliderData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--SliderData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--SliderData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SliderData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--SliderData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--SliderData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--SliderData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--SliderData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--SliderData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--SliderData-b-fontBold', branding.FontBBold)
            //Slider Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForSliderData = slider.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let sliderDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForSliderData).length;
            var multiImg = 0;
            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };
                dt.img = slider.LandingPageSectionBranding.MultipleImages[multiImg];

                groupByMetaGroupForSliderData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.description = data.MetaProperty.value;
                    }

                });
                sliderDataObj.push(dt);
                multiImg = multiImg + 1;
            }
        return (
            <div id="slider">

                <div className='container-fluid px-0'>
                    <div className="row px-0 m-0">
                        <div className="col-12 px-0 m-0">

                            <Carousel indicators={false} fade interval={10000}>
                                {sliderDataObj.map((item, i) => {
                                    return (
                                        <Carousel.Item>
                                            <img
                                                className="d-block w-100"
                                                src={item.img}
                                                alt=""
                                            />
                                            <Carousel.Caption>
                                                <h3 className="act-cap">Jenny</h3>
                                                <h4 className="act-cap-and">&</h4>
                                                <h3 className="act-cap">Mark</h3>

                                                <div className="slider-top-heading-border">
                                                    <h4 className="slider-top-heading">*** {item.description} ***</h4>
                                                </div>
                                                <div className="act-heart-divider animation fadeInUp animated">
                                                    <span className="slider-side-line"></span>
                                                    <i className="fa fa-heart slider-heart-left"></i>
                                                    <i className="fa fa-heart slider-heart-center"></i>
                                                    <i className="fa fa-heart slider-heart-right"></i>
                                                    <span className="slider-side-line"></span>
                                                </div>
                                            </Carousel.Caption>
                                        </Carousel.Item>
                                    )
                                })}
                                {/*<Carousel.Item>*/}
                                {/*    <img*/}
                                {/*        className="d-block w-100"*/}
                                {/*        src="http://html.everline.templaza.net/images/everline.jpg"*/}
                                {/*        alt="Third slide"*/}
                                {/*    />*/}
                                {/*    <Carousel.Caption>*/}
                                {/*        <h3 className="act-cap"> Selina<span className="act-and"> & </span>Jacos</h3>*/}
                                {/*    </Carousel.Caption>*/}
                                {/*</Carousel.Item>*/}

                                {/*<Carousel.Item>*/}
                                {/*    <img*/}
                                {/*        className="d-block w-100"*/}
                                {/*        src="http://html.everline.templaza.net/images/home121.jpg"*/}
                                {/*        alt="Second slide"*/}
                                {/*    />*/}

                                {/*    <Carousel.Caption>*/}
                                {/*        <h3 className="act-cap"> Salina & Fariha</h3>*/}
                                {/*    </Carousel.Caption>*/}
                                {/*</Carousel.Item>*/}



                            </Carousel>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    }
}