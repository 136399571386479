import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import { conferenceActionCreators } from './state/conferenceSlice'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../public/assets/conference2/scss/style.scss';
import SectionSelector from './SectionSelector';
import ThemeSettings from './components/branding/ThemeSettings';

interface IProps extends PropsFromRedux {
};

class Conference2 extends Component<IProps> {
    state = {
        sliderOpen: false,
        sectionID: 0,
    }
    constructor(props: any) {
        super(props);
        this.state = {
            sliderOpen: false,
            sectionID: 0
        }
        this.EditClick = this.EditClick.bind(this);
    }

    componentDidMount() {
        this.props.getConferenceLP();
    }
    componentDidUpdate() {

    }

    EditClick = (id: any) => {
        this.props.setThemeSettings(id, true)
    }

    render() {
        const initialValues = this.props.LandingPageDTO;

        return (
            <>
                 <ThemeSettings />
                <div className="act-Conf-2">

                    {initialValues.LandingPageSections.map((item, idx) => {
                        return (
                            <div className="section-edit-border">
                                <button onClick={() => this.EditClick(item.LandingPageSectionId)} className="section-edit-button"><i className="fa fa-pencil" /> EDIT</button>
                                <SectionSelector
                                    sectionName={item.LandingPageSectionName}
                                    key={idx + idx + idx}
                                    data={item}
                                />
                            </div>
                        )
                    })}
                </div>
            </>

        );
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.conference,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getConferenceLP: () => dispatch(conferenceActionCreators.fetchConferenceLP()),
        setThemeSettings: (id: number, isOpen: boolean) => dispatch(conferenceActionCreators.selectThemeSettings(id, isOpen))
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Conference2);