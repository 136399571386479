import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface SpeakerInfo {
    img?: string;
    name?: string;
}
interface Data {
    button?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Speaker extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const Speaker = this.props.data;
        if (Speaker !== undefined && Speaker !== null) {

            //Branding Variables

            var branding = Speaker.LandingPageSectionBranding;
            document.body.style.setProperty('--SpeakerData-bg-img', 'url(' + branding.BackgroundImage + ')')
            document.body.style.setProperty('--SpeakerData-bg-img-opacity', branding.BackgroundImageOpacity)
            document.body.style.setProperty('--SpeakerData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SpeakerData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--SpeakerData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--SpeakerData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--SpeakerData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--SpeakerData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--SpeakerData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--SpeakerData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--SpeakerData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--SpeakerData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--SpeakerData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SpeakerData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--SpeakerData-c-fontFamily', branding.FontCFamily)

            //Speaker Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForSpeakerData = Speaker.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let SpeakerDataObj: Data[] = [];
            let Sinfo: SpeakerInfo[] = [];

            var count = Object.keys(groupByMetaGroupForSpeakerData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { button: "" };
                let info: SpeakerInfo = { img: "" };

                groupByMetaGroupForSpeakerData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        info.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Name") {
                        info.name = data.MetaProperty.value;
                    }

                });
                Sinfo.push(info);
                SpeakerDataObj.push(dt);

            }

            return (
                <>
                    <div id="speaker">
                        <section id="speakers" className="our-speaker-area bg-img cn2-bg-gradient-overlay section-padding-100-60">
                            <div className="container">
                                <div className="row">

                                    <div className="col-12">
                                        <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                                            <h4 className="cnst-h3">{Speaker.LandingPageSectionDescription}</h4>
                                            <p className="cnst-speaker-p">{Speaker.LandingPageSectionHeading}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    {Sinfo.map((item, i) => {
                                        return (
                                            <div className="col-lg-4 col-md-6">
                                                <div className="speaker img-wrapper">
                                                    <img src={item.img} alt="Speaker 1" />
                                                    <div className="details">
                                                        <h3><a href="#">{item.name}</a></h3>
                                                        <p>Quas alias incidunt</p>
                                                        <p>Amazon</p>
                                                        <div className="social">
                                                            <a href="" className="speaker-social"><i className="fa fa-twitter"></i></a>
                                                            <a href="" className="speaker-social"><i className="fa fa-facebook"></i></a>
                                                            <a href="" className="speaker-social"><i className="fa fa-google-plus"></i></a>
                                                            <a href="" className="speaker-social"><i className="fa fa-linkedin"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                    <div className="col-12 mb-4">
                                        <div className="more-speaker-btn text-center mb-4">
                                            <a className="confer-btn-white mb-4" href="#">All Speakers <i className="fa fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </>
            );
        }
    }
}