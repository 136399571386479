import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}

export class Gallery extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const gallery = this.props.data;
        var branding = gallery.LandingPageSectionBranding;
        document.body.style.setProperty('--GalleryData-color-a-one', branding.ColorAOne)
        document.body.style.setProperty('--GalleryData-color-a-two', branding.ColorATwo)
        document.body.style.setProperty('--GalleryData-a-fontFamily', branding.FontAFamily)
        document.body.style.setProperty('--GalleryData-a-fontSize', branding.FontASize + "px")
        document.body.style.setProperty('--GalleryData-a-fontStyle', branding.FontAStyle)
        document.body.style.setProperty('--GalleryData-a-fontBold', branding.FontABold)
        return (
            <div id="gallery" className="wed3-gallery">
                <div className="container-fluid act-gallery-pad">
                    <div className="container">
                        <h3 className="wed3-gallery-h3">Captured Moments</h3>

                        <div className="act-gallery">
                            <div className="row"><div className="col-lg-12">
                                <div className="portfolio-grids gallery-container clearfix">
                                    <div className="grid"><div className="img-holder">
                                        <div className="react-fancybox">
                                            <div className="thumbnail">
                                                <img src="https://mylove-react.wpocean.com/static/media/1.958d31ec977022463b14.jpg" alt="thumbnail" />

                                            </div><span></span></div></div></div>

                                    <div className="grid"><div className="img-holder"><div className="react-fancybox"><div className="thumbnail"><img src="https://mylove-react.wpocean.com/static/media/2.681afc1f894f843f6112.jpg" alt="thumbnail" />

                                    </div><span></span></div></div></div><div className="grid"><div className="img-holder"><div className="react-fancybox"><div className="thumbnail">
                                        <img src="https://mylove-react.wpocean.com/static/media/3.9f50d74c502af8ccb207.jpg" alt="thumbnail" /></div><span></span></div></div></div><div className="grid"><div className="img-holder"><div className="react-fancybox"><div className="thumbnail">

                                            <img src="https://mylove-react.wpocean.com/static/media/4.6d9ad9a19534dd9ac907.jpg" alt="thumbnail" /></div><span></span></div></div></div><div className="grid"><div className="img-holder"><div className="react-fancybox"><div className="thumbnail">

                                                <img src="https://mylove-react.wpocean.com/static/media/5.9af5d51ff2c16ebebc89.jpg" alt="thumbnail" /></div><span></span></div></div></div></div></div></div></div>
                    </div>
                </div>
            </div>
        );
    }
}