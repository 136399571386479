import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    logo?: string;
    description?:string;
}

interface IProps {
    data: LandingPageSection;
}

export class NavbarHeader extends Component<IProps, {}> {

    render() {
        const header = this.props.data;
        if (header !== undefined && header !== null) {
            //Header Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForHeaderData = header.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let HeaderDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForHeaderData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "", logo: "", description: "" };

                groupByMetaGroupForHeaderData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Logo") {
                        dt.logo = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.description = data.MetaProperty.value;
                    }

                });
                HeaderDataObj.push(dt);
            }
            return (
                <section className="wedding header" id="header">
                    <div className="decore">
                        <img alt="" className="img-fluid" src="/assets/images/wedding-img/backgrounds/top-pattern.png" />
                        <img alt="" className="img-fluid" src="/assets/images/wedding-img/backgrounds/bottom-pattern.png" />
                    </div>
                    <div className="wedding-content">
                        <div className="wedding bg slider-bg">
                            <div className="bottom-0 set-abs girl"><img alt="" className="img-fluid"
                                id="girl" src={HeaderDataObj[0].img} /></div>
                            <Container>
                                <Row>
                                    <Col lg="5" className="offset-lg-7">
                                        <div className="center-text m-t-50">
                                            <div className="simple-text">
                                                <div className="set-relative rightFadeInOut header-text">
                                                    <img alt="" className="logo-h1" src={HeaderDataObj[0].logo} />
                                                </div>
                                                <p className="header-sub-text">
                                                    {HeaderDataObj[0].description}
                                                </p>
                                                <div className="rightfadediv">
                                                    <div>
                                                        <img alt="" className="img-fluid bottom-img"
                                                            src="/assets/images/wedding-img/bottom.png" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </section>
            )
        }
    }
}