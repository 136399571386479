import React from 'react';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
}

interface IProps {
    data: LandingPageSection;
}

export class Counter extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const counter = this.props.data;
        var branding = counter.LandingPageSectionBranding;
        document.body.style.setProperty('--CounterData-bg-img', 'url(' + branding.BackgroundImage + ')')
        document.body.style.setProperty('--CounterData-bg-img-opacity', branding.BackgroundImageOpacity)
        document.body.style.setProperty('--CounterData-color-a-one', branding.ColorAOne)
        document.body.style.setProperty('--CounterData-color-a-two', branding.ColorATwo)
        document.body.style.setProperty('--CounterData-color-a-three', branding.ColorAThree)
        document.body.style.setProperty('--CounterData-color-b-one', branding.ColorBOne)
        document.body.style.setProperty('--CounterData-color-b-two', branding.ColorBTwo)
        document.body.style.setProperty('--CounterData-a-fontFamily', branding.FontAFamily)
        document.body.style.setProperty('--CounterData-a-fontSize', branding.FontASize + "px")
        document.body.style.setProperty('--CounterData-a-fontStyle', branding.FontAStyle)
        document.body.style.setProperty('--CounterData-a-fontBold', branding.FontABold)
        document.body.style.setProperty('--CounterData-b-fontFamily', branding.FontBFamily)
        document.body.style.setProperty('--CounterData-b-fontSize', branding.FontBSize + "px")
        document.body.style.setProperty('--CounterData-b-fontStyle', branding.FontBStyle)
        document.body.style.setProperty('--CounterData-b-fontBold', branding.FontBBold)
        return (
            <div id="counter">
                <div className="bg">
                    <div className="bg-overlay"></div>

                    <div className="container act-counter">

                        <div className="top-heading-border">
                            <h4 className="counter-top-heading">{counter.LandingPageSectionDescription}</h4>
                        </div>

                        <h1 className="act-h1-counter">{counter.LandingPageSectionHeading}</h1>
                        <div className="act-heart-divider animation fadeInUp animated">
                            <span className="counter-side-line"></span>
                            <i className="fa fa-heart counter-heart-left"></i>
                            <i className="fa fa-heart counter-heart-center"></i>
                            <i className="fa fa-heart counter-heart-right"></i>
                            <span className="counter-side-line"></span>
                        </div>
                        <div className="row act-counter-timer">
                            <div className="col-3"></div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="counter-circle">
                                            <span className="days">00</span>
                                            <p className="time">Days</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="counter-circle">
                                            <span className="days">00</span>
                                            <p className="time">Hours</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="counter-circle">
                                            <span className="days">00</span>
                                            <p className="time">Minutes</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                        <div className="counter-circle">
                                            <span className="days">00</span>
                                            <p className="time">Seconds</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3"></div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}