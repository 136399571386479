import React from 'react';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading1?: string;
    heading2?: string;
    heading3?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Counter extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const counter = this.props.data;
        if (counter !== undefined && counter !== null) {

            var branding = counter.LandingPageSectionBranding;
            document.body.style.setProperty('--CounterData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--CounterData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--CounterData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--CounterData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--CounterData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--CounterData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--CounterData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--CounterData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--CounterData-a-fontBold', branding.FontABold)

            document.body.style.setProperty('--CounterData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--CounterData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--CounterData-b-fontStyle', branding.FontBStyle)

            //Counter Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForCounterData = counter.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let counterDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForCounterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading1: "" };

                groupByMetaGroupForCounterData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading1") {
                        dt.heading1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading2") {
                        dt.heading2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading3") {
                        dt.heading3 = data.MetaProperty.value;
                    }

                });
                counterDataObj.push(dt);
            }
            return (
                <div id="counter">
                    <div className="countdown">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-12 col-sm-12 p-2">
                                    <div className="row">
                                        <div className="col-2">
                                            <i className="heart fa fa-heart-o"></i>
                                        </div>
                                        <div className="col-10 act-counter-text">
                                            {counterDataObj[0].heading1}<br /><span>{counterDataObj[0].heading2}</span><br />{counterDataObj[0].heading3}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                            <div className="fac">
                                                <span className="days">00</span>
                                                <p className="time">Days</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                            <div className="fac">
                                                <span className="days">00</span>
                                                <p className="time">Hours</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                            <div className="fac">
                                                <span className="days">00</span>
                                                <p className="time">Minutes</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 p-2">
                                            <div className="fac">
                                                <span className="days">00</span>
                                                <p className="time">Seconds</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }
}