import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Sponser extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const Sponser = this.props.data;
        if (Sponser !== undefined && Sponser !== null) {

            var branding = Sponser.LandingPageSectionBranding;
            document.body.style.setProperty('--SponsorData-color-a-one', branding.ColorAOne)

            //Sponser Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForSponserData = Sponser.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let SponserDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForSponserData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };

                groupByMetaGroupForSponserData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                });
                SponserDataObj.push(dt);

            }
            return (
                <div id="sponsor">
                    <section className="brand-area section-gap">
                        <div className="container">
                            <div className="row logo-wrap">

                                {SponserDataObj.map((item, i) => {
                                    return (

                                        <a className="col single-img" href="#">
                                            <img className="d-block mx-auto" src={item.img} alt="" />
                                        </a>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}