import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class Pricing extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="pricing">
                <section className="our-ticket-pricing-table-area bg-img bg-gradient-overlay section-padding-100-0 jarallax">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="section-heading text-center wow fadeInUp" data-wow-delay="300ms">
                                    <p className="cnst-speaker-p">Choose a Ticket</p>
                                    <h4 className="cnst-h3">Ticket Pricing</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row cnst-ptbl-row">

                            <div className="col-12 col-lg-4">
                                <div className="single-ticket-pricing-table text-center mb-100 wow fadeInUp" data-wow-delay="300ms">
                                    <h6 className="ticket-plan">1 day pass</h6>

                                    <div className="ticket-icon">
                                        <img src="assets/conference1/imgs/sponser/p1.png" alt=""/>
                                    </div>
                                    <h2 className="ticket-price"><span>$</span>59</h2>
                                    <a href="#" className="pricing-confer-btn w-100 mb-30">Get Tickets <i className="fa fa-long-arrow-right"></i></a>

                                    <div className="ticket-pricing-table-details">
                                        <p><i className="fa fa-check"></i> One Day Conference Ticket</p>
                                        <p><i className="fa fa-check"></i> Coffee-break</p>
                                        <p><i className="fa fa-check"></i> Lunch and Networking</p>
                                        <p><i className="fa fa-check"></i> Keynote talk</p>
                                        <p><i className="fa fa-check"></i> Talk to the Editors Session</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="single-ticket-pricing-table active text-center mb-100 wow fadeInUp" data-wow-delay="300ms">
                                    <h6 className="ticket-plan">Full pass</h6>

                                    <div className="ticket-icon">
                                        <img src="assets/conference1/imgs/sponser/p2.png" alt=""/>
                                    </div>
                                    <h2 className="ticket-price"><span>$</span>99</h2>
                                    <a href="#" className="pricing-confer-btn w-100 mb-30">Get Tickets <i className="fa fa-long-arrow-right"></i></a>

                                    <div className="ticket-pricing-table-details">
                                        <p><i className="fa fa-check"></i> One Day Conference Ticket</p>
                                        <p><i className="fa fa-check"></i> Coffee-break</p>
                                        <p><i className="fa fa-check"></i> Lunch and Networking</p>
                                        <p><i className="fa fa-check"></i> Keynote talk</p>
                                        <p><i className="fa fa-check"></i> Talk to the Editors Session</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-6 col-lg-4">
                                <div className="single-ticket-pricing-table text-center mb-100 wow fadeInUp" data-wow-delay="300ms">
                                    <h6 className="ticket-plan">Group pass</h6>

                                    <div className="ticket-icon">
                                        <img src="assets/conference1/imgs/sponser/p3.png" alt="" />
                                    </div>
                                    <h2 className="ticket-price"><span>$</span>199</h2>
                                    <a href="#" className=" pricing-confer-btn w-100 mb-30">Get Tickets <i className="fa fa-long-arrow-right"></i></a>

                                    <div className="ticket-pricing-table-details">
                                        <p><i className="fa fa-check"></i> One Day Conference Ticket</p>
                                        <p><i className="fa fa-check"></i> Coffee-break</p>
                                        <p><i className="fa fa-check"></i> Lunch and Networking</p>
                                        <p><i className="fa fa-check"></i> Keynote talk</p>
                                        <p><i className="fa fa-check"></i> Talk to the Editors Session</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}