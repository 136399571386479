import {Action, configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer';
import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

const store = configureStore({
    reducer: rootReducer(history),
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()


export type RootState = ReturnType<typeof store.getState>

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;