import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    frameImg?: string;
    brideName?: string;
    groomName?: string;
    place?: string;
    address?: string;
}

interface IProps {
    data: LandingPageSection;
}
export class Banner extends Component<IProps, {}> {

    render() {
        const banner = this.props.data;
        if (banner !== undefined && banner !== null) {
            //Banner Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForBannerData = banner.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let BannerDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForBannerData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { frameImg: "", place: "", address: "" };

                groupByMetaGroupForBannerData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.frameImg = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "GroomName") {
                        dt.groomName = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "BrideName") {
                        dt.brideName = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Place") {
                        dt.place = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Address") {
                        dt.address = data.MetaProperty.value;
                    }

                });
                BannerDataObj.push(dt);
            }
            return (
                <section className="wedding banner jenny-bg bg set-relative">
                    <Container>
                        <Row>
                            <Col xs="12">
                                <div className="text-center">
                                    <div className="wedding-banner">
                                        <div className="set-relative rightFadeInOut">
                                            <img alt="" className="img-fluid frem" src={BannerDataObj[0].frameImg} />
                                        </div>
                                        <div className="abs-center">
                                            <div className="text-container">
                                                <div className="couple-text">
                                                    <h2 className="banner-text gradient-text">{BannerDataObj[0].brideName}</h2>
                                                    <h2 className="banner-text gradient-text small">&</h2>
                                                    <h2 className="banner-text gradient-text">{BannerDataObj[0].groomName}</h2>
                                                </div>
                                                <div>
                                                    <h3 className="text-uppercase place">{BannerDataObj[0].place}</h3>
                                                    <h4 className="address">{BannerDataObj[0].address}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}