import React, {Component} from 'react';
import Slider, {Settings} from 'react-slick'; 
import { LandingPageSection } from '../../../server/LandingPageModel';
import {Container,Row,Col} from 'reactstrap'

interface IProps {
    data: LandingPageSection;
}

export class Brand extends Component<IProps, {}> {
    SlickSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 5,
        autoplay: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 320,
                settings: { slidesToShow: 1 }
            },
            {
                breakpoint: 420,
                settings: { slidesToShow: 2 }
            },
            {
                breakpoint: 768,
                settings: { slidesToShow: 3 }
            },
            {
                breakpoint: 1199,
                settings: { slidesToShow: 4 }
            }
        ]
    };
    render() {
        const data = this.props.data;

        if (data !== undefined && data !== null) {
            return (

                <section className="wedding brand-sliders">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src="/assets/images/wedding-img/bottom.png" />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{data.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>{data.LandingPageSectionDescription}</p>
                                    </div>
                                </div>
                            </Col>

                            <Col xs="12">
                                <Slider className="owl-carousel owl-theme brand-slider" {...this.SlickSettings}>
                                    {data.MetaData.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <a href="#">
                                                    <img alt="" className="img-fluid wedding-brand" src={item.MetaProperty.value} />
                                                </a>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </Col>
                        </Row>
                        <div>
                        </div>
                    </Container>
                </section>
            )
        }
    }
}