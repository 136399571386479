import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './nav'

const CenterLogoHeader = (props:any) => {
    const [show, setShow] = useState(false)
    const [sidebar, setSidebar] = useState(false);

    const clickSidebar = () => {
        setSidebar(!sidebar)
        document.querySelector('.navbar')!.innerHTML = 'openSidebar';
    }

    return (
        <header className={`loding-header nav-abs custom-scroll header-rel wedding`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="navbar-expand-lg theme-nav w-100">
                            <Link to="/">
                                <a className="center-header d-none d-lg-block">
                                        <>
                                            <img alt="logo" className="logo-abs h-auto" src="/assets/images/logo/7.png" />
                                        </>
                                </a>
                            </Link>
                            <Link to="/">
                                <a className="logo-responsive navbar-brand d-md-block d-lg-none">
                                   
                                        <>
                                            <img alt="logo" src="/assets/images/logo/8.png" />
                                        </>
                                       
                                </a>
                            </Link>
                            <div className="responsive-btn ml-auto">
                                <a className="toggle-nav" >
                                    <i aria-hidden="true" className="fa fa-bars"></i>
                                </a>
                            </div>
                            <Nav />
                          </nav> 
                    </div>
                </div>
            </div>
        </header>
    )
}

export default CenterLogoHeader
