import React, { Component } from 'react';
import { NavbarHeader } from './components/NavbarHeader';
import { Slider } from './components/Slider';
import { About } from './components/About';
import { Speaker } from './components/Speaker';
//import { Schedule } from './components/schedule';
import { Sponser } from './components/Sponser';
import { Pricing } from './components/Pricing';
//import { Newsletter } from './components/newsletter';
import { Footer } from './components/Footer';
import { Map } from './components/Map';


interface IProps {
    sectionName: string; // section
    data?: any;
}

class SectionSelector extends Component<IProps> {

    components = {
        NavData: NavbarHeader,
        HeaderData: Slider,
        AboutData: About,
        SpeakerData: Speaker,
        PricingData: Pricing,
        SponserData: Sponser,
        //NewsletterData: Newsletter,
        FooterData: Footer,
        MapData: Map,

    };

    render() {
        const TagName = (this.components as any)[this.props.sectionName || 'empty'];
        return <TagName
            data={this.props.data}
        />
    }
}

export default SectionSelector;