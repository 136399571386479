import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class About extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="about">
                <section className="about-us-countdown-area" id="cnst-about">
                    <div className="container p-4 mb-4">
                        <div className="row align-items-center">

                            <div className="col-12 col-md-6">
                                <div className="about-content-text mb-80">
                                    <h6 className="cnst-h6">About Conference</h6>
                                    <h3 className="cnst-h3">Welcome to the Project Management</h3>
                                    <p className="cnst-p" >The Project Management is a platform to learn expert techniques for building successful project teams, creating efficient plans and implementing effective tracking measures to ensure your projects come in on deadline and on budget. In one fast-paced, well-designed day, we will cover all the essential elements of project management.</p>
                                    <a href="#" className="confer-btn mt-50"> Interested <i className="fa fa-long-arrow-right"></i></a>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                                <div className="about-thumb">
                                    <img className="about-thumb-img" src="https://rstheme.com/products/wordpress/evenio/wp-content/uploads/2021/11/about_new06.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="countdown-up-area mt-4">
                        <div className="container mt-4">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-3">

                                    <div className="countdown-content-text">
                                        <h6 className="cnst-h5">Conference Date</h6>
                                        <h4 className="cnst-h4">Count Every Second Until the Event</h4>
                                    </div>
                                </div>

                                <div className="col-12 col-md-9">
                                    <div className="countdown-timer mb-100">
                                        <div className="row" id="clock">
                                            <div className="col-lg-1">

                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 box-col">
                                                <div>00 <span>Months</span></div>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 box-col">
                                                <div>00 <span>Days</span></div>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 box-col">
                                                <div>00 <span>Hours</span></div>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 box-col">
                                                <div>00 <span>Minutes</span></div>
                                            </div>

                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 box-col">
                                                <div>00 <span>Seconds</span></div>
                                            </div>

                                            <div className="col-lg-1">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}