import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
const Nav = () => {
    const [sidebar, setSidebar] = useState(true);

    function closeSidebar() {
        //debugger;
        setSidebar(!sidebar)
        document.querySelector('.navbar')!.innerHTML = 'openSidebar';
    }

    return (
        <div className={`navbar`} id="togglebtn">
            <div className="responsive-btn">
                <a className="btn-back" onClick={closeSidebar}>
                    <h5>back</h5>
                </a>
            </div>
            <ul className="main-menu">
                <li className={``}>
                    <Link
                        to='/'
                        className={`active`}
                    >
                        <span>Wedding</span>
                         
                    </Link>

                </li>
                <li className={``}>
                    <Link
                        to='/event'
                        className={`active`}
                    >
                        <span>Event</span>

                    </Link>

                </li>
                <li className={``}>
                    <Link
                        to='/'
                        className={`active`}
                    >
                        <span>About</span>

                    </Link>
                </li>
                <li className={``}>
                    <Link
                        to='/'
                        className={`active`}
                    >
                        <span>Blog</span>

                    </Link>
                </li>
            </ul>

        </div>
    )
}

export default Nav
