import React, { Component } from 'react';
import { NavbarHeader } from './components/NavbarHeader';
import { About } from './components/About';
import { Banner } from './components/Banner';
import { Countdown } from './components/Countdown';
import { Blog } from './components/Blog';
import { Testimonial } from './components/Testimonial';
import { Brand } from './components/Brand';
import { Destination } from './components/Destination';
import { Counter } from './components/Counter';
import { Subscribe } from './components/Subscribe';
import { Footer } from './components/Footer';

interface IProps {
    sectionName: string; // section
    data?: any;
}

class SectionSelector extends Component<IProps> {

    components = {
        HeaderData: NavbarHeader,
        AboutData: About,
        BannerData: Banner,
        CountDownData: Countdown,
        BlogData: Blog,
        TestimonialData: Testimonial,
        BrandData: Brand,
        DestinationData: Destination,
        CounterData: Counter,
        SubscribeData: Subscribe,
        FooterData: Footer,
    };

    render() {
        const TagName = (this.components as any)[this.props.sectionName || 'empty'];
        return <TagName
            data={this.props.data}
        />
    }
}

export default SectionSelector;