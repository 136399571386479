import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

import CountUp from 'react-countup';

interface Data {
    img?: string;
    title?: string;
    count?: number;
}

interface IProps {
    data: LandingPageSection;
}
export class Counter extends Component<IProps, {}> {

    render() {
        const counter = this.props.data;
        if (counter !== undefined && counter !== null) {

            //Counter Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForCounterData = counter.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let CounterDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForCounterData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { title: "", img: "", count: 0 };

                groupByMetaGroupForCounterData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.title = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Count") {
                        dt.count = data.MetaProperty.value;
                    }

                });
                CounterDataObj.push(dt);
            }

            return (
                <section className="wedding counter">
                    <Container>
                        <Row>
                            {CounterDataObj.map((item, i) => {
                                return (
                                    <Col md="3" xs="6" className="counter-container" key={i}>
                                        <div className="counters">
                                            <img alt="" className="img-fluid counter-img" src={item.img} />
                                            <div className="counter-text">
                                                <div className="count-number">
                                                    <h2 className="counts text-center">
                                                        <CountUp end={item.count || 0} />
                                                    </h2>
                                                </div>
                                                <h6 className="count-desc text-center">{item.title}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}