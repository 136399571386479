import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../../../store/store';
import { weddingActionCreators } from '../../state/weddingSlice'
import { BrandingKit } from '../../../../server/LandingPageModel';

interface IProps extends PropsFromRedux {
    data: BrandingKit;
    onChange: any;
    selectedId: any;
}

class Kit extends React.Component<IProps> {
    state = {
        active: false
    };

    componentDidMount() {
        this.props.getBrandingKits();
    }
    componentDidUpdate() {

    }

    hover = () => {
        this.setState({
            active: !this.state.active
        });
    };

    kitChange = (id: number) => {
        
        this.props.onChange(id);
       
    }

    render() {
        const bk = this.props.data;
        const defaults = bk.BrandingKitDefaults;

        return (
            <div className="col-md-6" key={bk.BrandingKitID}>
                <label className="st-label-text">{bk.Name} <span className={"badge bg-danger " + (this.props.selectedId === bk.BrandingKitID ? "active-kit" : "inactive-kit")}>Active</span></label>
                <div className="st-img-div">

                    <img className="st-img" onClick={() => this.kitChange(bk.BrandingKitID)} src="assets/conference2/imgs/conference2thumbnail.jpg"></img>
                    <i className="fa fa-eye st-fa-download"></i>
                </div>
                <div className="st-sec pb-2">
                    <div className="row p-4 pt-2 pb-1">
                        <div className="color-box" style={{ backgroundColor: defaults.ColorOne }}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorTwo }}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorThree }}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorFour }}></div>

                    </div>
                    <div className="row p-4 pt-2 pb-1">
                        <div className="color-box" style={{ backgroundColor: defaults.ColorFive }}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorSix }}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorSeven}}></div>
                        <div className="color-box" style={{ backgroundColor: defaults.ColorEight}}></div>

                    </div>
                    <div className="row p-4 pt-1 pb-1">
                        <div className="col-md-12 ps-0">
                            <h4 className="st-font-p" style={{ fontFamily: defaults.FontFamilyOne}}>Font Family 1</h4>
                            <h4 className="st-font-s" style={{ fontFamily: defaults.FontFamilyTwo}}>Font Family 2</h4>
                            <h4 className="st-font-s" style={{ fontFamily: defaults.FontFamilyThree}}>Font Family 3</h4>
                        </div>
                    </div>
                  
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: AppState.RootState) => ({
    ...state.wedding2,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getBrandingKits: () => dispatch(weddingActionCreators.fetchBrandingKits())
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Kit);