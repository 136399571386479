import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Gallery extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const gallery = this.props.data;
        if (gallery !== undefined && gallery !== null) {

            var branding = gallery.LandingPageSectionBranding;
            document.body.style.setProperty('--GalleryData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--GalleryData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--GalleryData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--GalleryData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--GalleryData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--GalleryData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--GalleryData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--GalleryData-a-fontBold', branding.FontABold)
            //gallery Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForGalleryData = gallery.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let DataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForGalleryData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };

                groupByMetaGroupForGalleryData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }

                });
                DataObj.push(dt);
            }
            return (
                <div id="gallery">
                    <div className="container-fluid act-gallery-pad">
                        <div className="act-ourstory">
                            <div className="container">
                                <h3 className="act-title_gallery">{gallery.LandingPageSectionHeading}</h3>
                                <div className="act-heart-divider animation fadeInUp animated">
                                    <span className="gallery-side-line"></span>
                                    <i className="fa fa-heart gallery-heart-left"></i>
                                    <i className="fa fa-heart gallery-heart-center"></i>
                                    <i className="fa fa-heart gallery-heart-right"></i>
                                    <span className="gallery-side-line"></span>
                                </div>
                                <div className="act-gallery">
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="portfolio-grids gallery-container clearfix">
                                                {DataObj.map((item, i) => {
                                                    return (
                                                        <div className="grid"><div className="img-holder">
                                                            <div className="react-fancybox">
                                                                <div className="thumbnail">

                                                                    <img src={item.img} alt="thumbnail" />

                                                                </div><span></span></div></div>
                                                        </div>

                                                    )
                                                })}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}