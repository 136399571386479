import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Offcanvas,
} from 'react-bootstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface NavItem {
    item?: string;
}
interface Data {
    logo?: string;
    navItem?: NavItem[];
    buttonText?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class NavbarHeader extends React.Component<IProps, {}> {
    state = {
        headerShrink: "cn2-header",
        navbarNAV: "cn2-navbar-nav",
        navbarNavRight: "cn2-navbar-nav-right",
    };

    constructor(props: any) {
        super(props);

        this.state = {
            headerShrink: "cn2-header",
            navbarNAV: "cn2-navbar-nav",
            navbarNavRight: "cn2-navbar-nav-right"
        };
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 200;

        if (distanceY > shrinkOn) {
            this.setState({ headerShrink: "cn2-header1" });
            this.setState({ logoShrink: "" });
            this.setState({ navbarNAV: "cn2-navbar-navShrink" });
            this.setState({ navbarNavRight: "cn2-navbar-navShrink-right" });
        } else {
            this.setState({ headerShrink: "cn2-header" });
            this.setState({ logoShrink: "cn2-logoBrand" });
            this.setState({ navbarNAV: "cn2-navbar-nav" });
            this.setState({ navbarNavRight: "cn2-navbar-nav-right" });
        }
    }
    render() {
        const nav = this.props.data;
        if (nav !== undefined && nav !== null) {

            var branding = nav.LandingPageSectionBranding;
            document.body.style.setProperty('--NavData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--NavData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--NavData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--NavData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--NavData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--NavData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--NavData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--NavData-a-fontSize', branding.FontASize+"px")
            document.body.style.setProperty('--NavData-a-fontStyle', branding.FontAStyle)

            //About Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForNavData = nav.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let NavDataObj: Data[] = [];
            let NavItemObj: NavItem[] = [];

            var count = Object.keys(groupByMetaGroupForNavData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { logo: "" };
                let item: NavItem = { item: "" };

                groupByMetaGroupForNavData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.logo = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.buttonText = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "NavItem") {
                        item.item = data.MetaProperty.value;
                        NavItemObj.push(item);
                    }

                });
                NavDataObj.push(dt);

            }
            return (
                <div>
                    <Container fluid className="px-0">
                        <div className={this.state.headerShrink} >
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <Navbar key="lg" expand="lg" className="mb-0">
                                        <Container>
                                            <Navbar.Brand href="/conference2" className="">
                                            </Navbar.Brand>
                                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}> <i className="fa fa-bars"></i> </Navbar.Toggle>
                                            <Navbar.Offcanvas
                                                id={`offcanvasNavbar-expand-lg`}
                                                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                                                placement="end"
                                                className="cn2-offcanvas"
                                                style={{
                                                    backgroundColor: "#008069",
                                                    color: "white",
                                                }}
                                            >
                                                <Offcanvas.Header closeButton closeVariant="white">
                                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>

                                                    </Offcanvas.Title>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body>
                                                    <Nav className={this.state.navbarNAV}>
                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#top">Top</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#about">ABOUT</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#speaker">SPEAKER</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#pricing">PRICING</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#sponsor">SPONSOR</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item className="btn-margin">
                                                            <Nav.Link className="cn2-btn" href="/event">Register</Nav.Link>
                                                        </Nav.Item>

                                                    </Nav>
                                                </Offcanvas.Body>
                                            </Navbar.Offcanvas>
                                        </Container>
                                    </Navbar>

                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            );
        }
    }
}