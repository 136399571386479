import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

import BottomImg from '../../../../public/assets/images/wedding-img/bottom.png';

interface Data {
    img?: string;
    title?: string;
    smallHeading?: string;
    descOne?: string;
    descTwo?: string;
    buttonText?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class About extends Component<IProps, {}> {

    render() {
        const about = this.props.data;
        if (about !== undefined && about !== null) {
            //About Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForAboutData = about.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let AboutDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForAboutData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "", smallHeading: "", descOne: "" };

                groupByMetaGroupForAboutData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "SmallHeading") {
                        dt.smallHeading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading") {
                        dt.title = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "ParagraphOne") {
                        dt.descOne = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "ParagraphTwo") {
                        dt.descTwo = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.buttonText = data.MetaProperty.value;
                    }

                });
                AboutDataObj.push(dt);
            }
            return (
                <section className="wedding format">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src={BottomImg} />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{about.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>
                                            {about.LandingPageSectionDescription}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="4" md="6" className="offset-xl-2">
                                <img alt="" className="img-fluid about-img" src={AboutDataObj[0].img} />
                            </Col>
                            <Col xl="4" lg="6" md="6">
                                <div className="center-text">
                                    <div>
                                        <div className="format-small-text">
                                            <h6>{AboutDataObj[0].smallHeading}</h6>
                                        </div>
                                        <div className="format-head-text">
                                            <h3 className="about-font-header gradient-text">{AboutDataObj[0].title}</h3>
                                        </div>
                                        <div className="format-sub-text">
                                            <p className="about-para">
                                                {AboutDataObj[0].descOne}
                                            </p>
                                            <p className="about-para">
                                                {AboutDataObj[0].descTwo}
                                            </p>
                                        </div>
                                        <a className=" btn btn-default btn-gradient text-white" href="#">{AboutDataObj[0].buttonText}</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}