import React, {Component} from 'react';
import Slider, {Settings} from 'react-slick'; 
import { Container, Row, Col } from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

const LeftNavButton = (props:any) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow"
            style={{ ...style, display: 'block', position: 'absolute', bottom: '50%', marginLeft: '-10%' }}
            onClick={onClick}
        >
            <img src="/assets/images/wedding-img/when/next.png" alt="arrow_left" />
        </div>
    );
}

const RightNavButton = (props:any) => {
    const { style, onClick } = props
    return (
        <div
            className="slick-arrow"
            style={{ ...style, display: 'block', position: 'absolute', bottom: '50%', marginLeft: '105%' }}
            onClick={onClick}
        >
            <img src="/assets/images/wedding-img/when/prev.png" alt="arrow_left" />
        </div>
    );
}

interface Data {
    img?: string;
    title?: string;
    timing?: string;
    feature1?: string;
    feature2?: string;
    feature3?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Destination extends Component<IProps, {}> {
 
    SlickSettings: Settings = {
        dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    prevArrow: <LeftNavButton />,
    nextArrow: <RightNavButton />,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: { slidesToShow: 1 }
        }
    ]
    };
    render() {

        const destination = this.props.data;
        if (destination !== undefined && destination !== null) {

            //Destination Data Object Creating
            var groupByMetaGroupForDestinationData = destination.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let DestinationDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForDestinationData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { title: "", img: ""};

                groupByMetaGroupForDestinationData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.title = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Timing") {
                        dt.timing = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Feature1") {
                        dt.feature1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Feature2") {
                        dt.feature2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Feature3") {
                        dt.feature3 = data.MetaProperty.value;
                    }

                });
                DestinationDataObj.push(dt);
            }

            return (
                <section className="wedding pricing when-where-bg bg set-relative">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src="/assets/images/wedding-img/bottom.png" />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{this.props.data.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>{this.props.data.LandingPageSectionDescription}</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <Slider className="owl-carousel owl-theme when-slider" {...this.SlickSettings}>
                                    {DestinationDataObj.map((item, i) => {
                                        return (
                                            <div className="item" key={i}>
                                                <div className="price-container bg-white price-margin shadows text-center">
                                                    <div className="price-feature-container set-relative">
                                                        <div className="feature-text">
                                                            <img alt="" className="img-fluid feature-icon"
                                                                src={item.img} />

                                                            <hr className="set-border" />
                                                            <h3 className="feature-text-heading text-center bold gradient-text ">{item.title}</h3>
                                                            <div className="price-value">
                                                                <h6 className="price text-center font-primary">{item.timing}</h6>
                                                            </div>
                                                        </div>
                                                        <div className="price-features font-primary">
                                                            <h4 className="price-feature font-primary">{item.feature1} </h4>
                                                            <h4 className="price-feature font-primary">{item.feature2} </h4>
                                                            <h4 className="price-feature font-primary m-0">{item.feature3}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}