import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getList } from '../../../common/genericApi';
import { AppThunk, RootState } from '../../../store/store';
import { LandingPageDTO, LandingPageSection, LandingPageType } from '../../../server/LandingPageModel';
import { getCLS } from 'web-vitals';


export interface WeddingLPState {
    LandingPageDTO: LandingPageDTO;
    loading: boolean;
    error: string;
}


const initialState: WeddingLPState = {
    LandingPageDTO: {
        LandingPageId: 0,
        LandingPageName: "",
        LandingPageDescription: "",
        isPrivate: false,
        LandingPageType: {} as LandingPageType,
        LandingPageSections: [] as LandingPageSection[],
    },
    loading: false,
    error: "",
}

export const weddingSlice = createSlice({
    name: 'wedding',
    initialState,
    reducers: {

        GetStart(state) {
            state.loading = true;

        },
        //ResultsError(state, action: PayloadAction<ApiModel<EventTypeDTO[]>>) {
        //    state.EventTypes = action.payload.value;
        //    state.loading = false;
        //},
        LandingPageReceived(state, action: PayloadAction<LandingPageDTO>) {
            state.LandingPageDTO = action.payload;     
            state.loading = false; 
        },

    },
})

// Action creators are generated for each case reducer function
export const {
    GetStart,
    LandingPageReceived
} = weddingSlice.actions

export default weddingSlice.reducer

export const fetchWeddingLP = (): AppThunk => async dispatch => {
    try {
     
        dispatch(GetStart)
        const Result = await getList<LandingPageDTO>('api/LandingPages/Wedding')

        dispatch(LandingPageReceived(Result))
    } catch (err: any) {
        //dispatch(getSiteSettingsFailure(err))
    }
}