import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading1?: string;
    heading2?: string;
    heading3?: string;
    description1?: string;
    description2?: string;
    description3?: string;
    description4?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Footer extends React.Component<IProps, {}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const Footer = this.props.data;
        if (Footer !== undefined && Footer !== null) {
            var branding = Footer.LandingPageSectionBranding;
            document.body.style.setProperty('--FooterData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--FooterData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--FooterData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--FooterData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--FooterData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--FooterData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--FooterData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--FooterData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--FooterData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--FooterData-c-fontFamily', branding.FontCFamily)

            //Header Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForAboutData = Footer.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let DataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForAboutData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading1: "" };

                groupByMetaGroupForAboutData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.heading1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description1") {
                        dt.description1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading2") {
                        dt.heading2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description2") {
                        dt.description2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading3") {
                        dt.heading3 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description3") {
                        dt.description3 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description4") {
                        dt.description4 = data.MetaProperty.value;
                    }

                });
                DataObj.push(dt);

            }

            return (
                <div>

                    <footer className="footer-area section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 col-md-6 col-sm-6">
                                    <div className="single-footer-widget">
                                        <h6>{DataObj[0].heading1}</h6>
                                        <p>
                                            {DataObj[0].description1}
                                        </p>
                                        <p className="footer-text-copyright">

                                            {DataObj[0].description2}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-5  col-md-6 col-sm-6">
                                    <div className="single-footer-widget">
                                        <h6>{DataObj[0].heading2}</h6>
                                        <p>{DataObj[0].description3}</p>
                                        <div className="" id="mc_embed_signup">
                                            <form target="_blank" action="#" method="get" className="form-inline">
                                                <input className="form-control" name="EMAIL" placeholder="Enter Email" type="email" />
                                                <button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true"></i></button>
                                                <div className="cn2-footer-input-abs">
                                                    <input name="b_36c4fd991d266f23781ded980_aefe40901a" value="" type="text" />
                                                </div>

                                                <div className="info"></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-6 col-sm-6 social-widget">
                                    <div className="single-footer-widget">
                                        <h6>{DataObj[0].heading3}</h6>
                                        <p>{DataObj[0].description4}</p>
                                        <div className="footer-social d-flex align-items-center">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                            <a href="#"><i className="fa fa-dribbble"></i></a>
                                            <a href="#"><i className="fa fa-behance"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>

                </div>
            );
        }
    }
}