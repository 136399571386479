import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    heading?: string;
    description1?: string;
    description2?: string;
    description3?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Story extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const story = this.props.data;
        if (story !== undefined && story !== null) {

            var branding = story.LandingPageSectionBranding;
            document.body.style.setProperty('--StoryData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--StoryData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--StoryData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--StoryData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--StoryData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--StoryData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--StoryData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--StoryData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--StoryData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--StoryData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--StoryData-b-fontStyle', branding.FontBStyle)

            //Story Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForStoryData = story.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let storyDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForStoryData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { heading: "" };

                groupByMetaGroupForStoryData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description1") {
                        dt.description1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description2") {
                        dt.description2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description3") {
                        dt.description3 = data.MetaProperty.value;
                    }

                });
                storyDataObj.push(dt);
            }
            return (
                <div id="story" className="wed1-story">
                <div className="container">
                    <div className="tzourstory">
                        <div className="container">
                            <h3 className="title_ourstory">{storyDataObj[0].heading}</h3>
                            <div className="ourstory_content">
                                <p>{storyDataObj[0].description1}</p>
                                <p>{storyDataObj[0].description2}</p>
                                <p>{storyDataObj[0].description3}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    }
}