const prod = {
    urls: {
        API_URL: 'https://landingpages.eventdone.com/app/'
    }
};

const dev = {
    urls: {
        API_URL: 'https://localhost:7033/'
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;