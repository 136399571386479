import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Conference1 from './features/conference1/conference';
import { RootPage } from './features/RootPage';
import Conference2 from './features/conference2/conference';
import WeddingPage from './features/wedding/wedding';
import EventPage from './features/event/event';
import Wedding1 from './features/Wedding2.O/Wedding';
import Wedding2 from './features/wedding2.1/Wedding';
import Wedding3 from './features/wedding2.2/Wedding';

import 'bootstrap-scss';
import '../public/assets/scss/flaticon.scss';
import '../public/assets/scss/font-awesome.scss';
import '../public/assets/scss/themify.scss';
import "../public/assets/scss/color-1.scss";
import "../public/assets/scss/slick.scss";
import "../public/assets/scss/slick-theme.scss";
import "@fontsource/rouge-script";
import "@fontsource/great-vibes";
import "@fontsource/poppins";


export interface IApplicationProps { }

const App: React.FunctionComponent<IApplicationProps> = (props) => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RootPage />} />
                <Route path="/wedding" element={<WeddingPage />} />
                <Route path="/event" element={<EventPage />} />
                <Route path="/conference1" element={<Conference1 />} />
                <Route path="/conference2" element={<Conference2 />} />
                <Route path="/wedding1" element={<Wedding1 />} />
                <Route path="/wedding2" element={<Wedding2 />} />
                <Route path="/wedding3" element={<Wedding3 />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;