import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    number?: number;
    name?: string;
    planFor?: string;
    data1?: string;
    data2?: string;
    data3?: string;
    price?: string;
    button?: string;
}
 
interface IProps {
    data: LandingPageSection;
}

export class Pricing extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const Pricing = this.props.data;
        if (Pricing !== undefined && Pricing !== null) {

            //Branding Variables

            var branding = Pricing.LandingPageSectionBranding;
            document.body.style.setProperty('--PricingData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--PricingData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--PricingData-color-a-three', branding.ColorAThree)
            document.body.style.setProperty('--PricingData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--PricingData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--PricingData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--PricingData-color-b-four', branding.ColorBFour)
            document.body.style.setProperty('--PricingData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--PricingData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--PricingData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--PricingData-color-c-four', branding.ColorCFour)
            document.body.style.setProperty('--PricingData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--PricingData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--PricingData-c-fontFamily', branding.FontCFamily)

            //Speaker Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForPricingData = Pricing.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let PricingDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForPricingData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { button: "" };

                groupByMetaGroupForPricingData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Number") {
                        dt.number = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Name") {
                        dt.name = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "PlanFor") {
                        dt.planFor = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Data1") {
                        dt.data1 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Data2") {
                        dt.data2 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Data3") {
                        dt.data3 = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Price") {
                        dt.price = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.button = data.MetaProperty.value;
                    }

                });
                PricingDataObj.push(dt);

            }
            return (
                <div id="pricing">
                    <section className="price-area section-gap">
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="menu-content pb-70 col-lg-8">
                                    <div className="title text-center">
                                        <h1 className="mb-10 heading-h1">{Pricing.LandingPageSectionHeading}</h1>
                                        <p className="heading-p">{Pricing.LandingPageSectionDescription}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row cn2-price-box">
                                {PricingDataObj.map((item, i) => {
                                    return (

                                        <div className="col-lg-3 col-md-6 single-price">
                                            <div className="top-part">
                                                <h1 className="package-no">{item.number}</h1>
                                                <h4>{item.name}</h4>
                                                <p>{item.planFor}</p>
                                            </div>
                                            <div className="package-list">
                                                <ul>
                                                    <li>{item.data1}</li>
                                                    <li>{item.data2}</li>
                                                    <li>{item.data3}</li>
                                                </ul>
                                            </div>
                                            <div className="bottom-part">
                                                <h1>{item.price}</h1>
                                                <a className="price-btn text-uppercase" href="#">{item.button}</a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </section>
                </div>
            );
        }
    }
}