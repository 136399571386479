import axios, { AxiosRequestConfig, AxiosResponse, AxiosRequestHeaders } from 'axios';
import { config } from '../Constants'
//import { Cookies } from 'react-cookie';
//import authService from '../api-authorization/AuthorizeService';


export async function getHeader(token?: any) {
    //token = await authService.getAccessToken();
    //const user = await authService.getUser();
    //const token = user &&  user.access_token;

    const authToken = !token ? {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'

    } : {
        //'Authorization': `Bearer ${token}`,
        'X-XSRF-TOKEN': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
    };
    return authToken;
}

export async function getList<T>(url: string, token?: any) {
    const { data } = await axios.get<T>(config.urls.API_URL + url);
    //const { data } = await axios.get<T>(config.urls.API_URL + url, { headers: await getHeader(token) as AxiosRequestHeaders, withCredentials: true });
    return data
}


export async function get<T>(url: string, id?: string, token?: any) {
    const idcheck = id ? '/' + id : ''
    const { data } = await axios.get<T>(config.urls.API_URL + url + idcheck, { headers: await getHeader(token) as AxiosRequestHeaders, withCredentials: true });
    return data
}

export async function post<T>(url: string, values: any, token?: any) {
    const options: AxiosRequestConfig = {
        url: "https://localhost:5001" + url,
        method: 'POST',
        withCredentials: true,
        headers: await getHeader(token) as AxiosRequestHeaders,
        //data: { ...values },
        data: JSON.stringify(values),
    };
    const data = await axios(options)
        .then((response: AxiosResponse<T>) => {
            console.log(response.status);
            return response.data;
        });
    return data
}

export async function put<T>(url: string, values: any, id: any, token?: any) {
    const options: AxiosRequestConfig = {
        url: config.urls.API_URL + url + id,
        method: 'PUT',
        withCredentials: true,
        headers: await getHeader(token) as AxiosRequestHeaders,
        data: {
            ...values
        }
    };
    const data = await axios(options)
        .then((response: AxiosResponse<T>) => {
            console.log(response.status);
            return response.data;
        });

    return data
}

export async function deleteItem<T>(url: string, id: any, token?: any) {
    const options: AxiosRequestConfig = {
        url: config.urls.API_URL + url + id,
        method: 'DELETE',
        withCredentials: true,
        headers: await getHeader(token) as AxiosRequestHeaders,
    };
    const data = await axios(options)
        .then((response: AxiosResponse<T>) => {
            console.log(response.status);
            return response.data;
        });
    return data
}