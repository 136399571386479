import { combineReducers, Reducer } from '@reduxjs/toolkit'
import weddingReducer, { WeddingLPState } from '../features/wedding/state/weddingSlice'
import wedding1Reducer, { Wedding1LPState } from '../features/Wedding2.O/state/weddingSlice'
import wedding2Reducer, { Wedding2LPState } from '../features/wedding2.1/state/weddingSlice'
import wedding3Reducer, { Wedding3LPState } from '../features/wedding2.2/state/weddingSlice'
import conferenceReducer, { ConferenceLPState } from '../features/conference2/state/conferenceSlice'
import conference1Reducer, { Conference1LPState } from '../features/conference1/state/conferenceSlice'


export interface Reducers {
    [s: string]: Reducer
}

export interface ApplicationState {
    wedding: WeddingLPState,
    wedding1: Wedding1LPState,
    wedding2: Wedding2LPState,
    wedding3: Wedding3LPState,
    conference: ConferenceLPState,
    conference1: Conference1LPState,
    //event: eventReducer,
}


const rootReducer = (history: History) => combineReducers<ApplicationState>({
    wedding: weddingReducer,
    wedding1: wedding1Reducer,
    wedding2: wedding2Reducer,
    wedding3: wedding3Reducer,
    conference: conferenceReducer,
    conference1: conference1Reducer,
   // event: eventReducer,
})

export default rootReducer