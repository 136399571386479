import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    button?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class NewsLetter extends React.Component<IProps, {}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const Newsletter = this.props.data;
        if (Newsletter !== undefined && Newsletter !== null) {
            var branding = Newsletter.LandingPageSectionBranding;
            document.body.style.setProperty('--NewsLetterData-bg-img', 'url(' +branding.BackgroundImage + ')')
            document.body.style.setProperty('--NewsLetterData-bg-img-opacity', branding.BackgroundImageOpacity)
            document.body.style.setProperty('--NewsLetterData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--NewsLetterData-color-a-two', branding.ColorATwo)
            document.body.style.setProperty('--NewsLetterData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--NewsLetterData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--NewsLetterData-color-b-three', branding.ColorBThree)
            document.body.style.setProperty('--NewsLetterData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--NewsLetterData-b-fontFamily', branding.FontBFamily)

            //Newsletter Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForAboutData = Newsletter.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let DataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForAboutData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { button: "" };

                groupByMetaGroupForAboutData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Button") {
                        dt.button = data.MetaProperty.value;
                    }
                });
                DataObj.push(dt);

            }
        return (
			<div>
				<section className="callaction-area section-gap">
					<div className="cn2-overlay cn2-overlay-bg"></div>
					<div className="container">
						<div className="row justify-content-center">
                            <div className="col-lg-10">
                                <h1 className="">{Newsletter.LandingPageSectionHeading}</h1>
								<p>
                                    {Newsletter.LandingPageSectionDescription}
								</p>
								<a className="callaction-btn" href="#">Become a Member</a>
							</div>
						</div>
					</div>
				</section>
            </div>
        );
    }
    }
}