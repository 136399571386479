import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Offcanvas,
} from 'react-bootstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface NavItem {
    item?: string;
}
interface Data {
    logo?: string;
    navItem?: NavItem[];
    buttonText?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class NavbarHeader extends React.Component<IProps, {}> {
    state = {
        isOpen: false,
        logoShrink: "act-logoBrand",
        headerShrink: "act-header",
        navbarNAV: "act-navbar-nav",
    };

    constructor(props: any) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            logoShrink: "act-logoBrand",
            headerShrink: "act-header",
            navbarNAV: "act-navbar-nav"
        };
        window.addEventListener('scroll', this.resizeHeaderOnScroll);
    }

    resizeHeaderOnScroll = () => {
        const distanceY = window.pageYOffset || document.documentElement.scrollTop,
            shrinkOn = 100;

        if (distanceY > shrinkOn) {
            this.setState({ headerShrink: "act-header1" });
            this.setState({ logoShrink: "" });
            this.setState({ navbarNAV: "act-navbar-navShrink" });
        } else {
            this.setState({ headerShrink: "act-header" });
            this.setState({ logoShrink: "act-logoBrand" });
            this.setState({ navbarNAV: "act-navbar-nav" });
        }
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    render() {
        const nav = this.props.data;
        if (nav !== undefined && nav !== null) {

            var branding = nav.LandingPageSectionBranding;
            document.body.style.setProperty('--NavData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--NavData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--NavData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--NavData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--NavData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--NavData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--NavData-a-fontBold', branding.FontABold)

            //About Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForNavData = nav.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let NavDataObj: Data[] = [];
            let NavItemObj: NavItem[] = [];

            var count = Object.keys(groupByMetaGroupForNavData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { logo: "" };
                let item: NavItem = { item: "" };

                groupByMetaGroupForNavData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.logo = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.buttonText = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "NavItem") {
                        item.item = data.MetaProperty.value;
                        NavItemObj.push(item);
                    }

                });
                NavDataObj.push(dt);
            }
            return (
                <div id="top">

                    <Container fluid className="px-0">

                        <div className={this.state.headerShrink} >
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12">

                                    <Navbar key="lg" expand="lg" className="mb-0">
                                        <Container>
                                            <Navbar.Brand href="/wedding1" className="">
                                            </Navbar.Brand>
                                            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}> <i className="fa fa-bars"></i> </Navbar.Toggle>
                                            <Navbar.Offcanvas
                                                id={`offcanvasNavbar-expand-lg`}
                                                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                                                placement="end"
                                                className="cn2-offcanvas"
                                                style={{
                                                    backgroundColor: "#008069",
                                                    color: "white",
                                                }}
                                            >
                                                <Offcanvas.Header closeButton closeVariant="white">
                                                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>

                                                    </Offcanvas.Title>
                                                </Offcanvas.Header>
                                                <Offcanvas.Body className="m-auto">
                                                    <Nav className={this.state.navbarNAV}>
                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#top">Home</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#savethedate">Save the Date</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#story">Our Story</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#gallery">Gallery</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#weddingparty">Wedding Party</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#agenda">Schedule</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#blog">REGISTRY</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#">Map</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link className="cn2-navlink" href="#social">Social</Nav.Link>
                                                        </Nav.Item>

                                                    </Nav>
                                                </Offcanvas.Body>
                                            </Navbar.Offcanvas>
                                        </Container>
                                    </Navbar>

                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            );
        }
    }
}