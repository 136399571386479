import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';

export class Sponser extends React.Component {

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <div id="sponsor">
                <section className="our-sponsor-client-area section-padding-100">
                    <div className="container">
                        <div className="row">

                            <div className="col-12">
                                <div className="section-heading-2 text-center">
                                    <p className="cnst-speaker-p">Partners &amp; Sponsors</p>
                                    <h4 className="cnst-h3">OFFICIAL SPONSOR</h4>
                                </div>
                            </div>
                        </div>

                        <div className="our-sponsor-area">
                            <div className="row">

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-1.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-2.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-3.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-4.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-5.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-6.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-7.png" alt="" /></a>
                                </div>

                                <div className="single-sponsor col-lg-3 col-md-4 col-sm-6 col-xs-6">
                                    <a href="#"><img src="assets/conference1/imgs/sponser/p-8.png" alt="" /></a>
                                </div>

                            </div>

                        </div>

                    </div>
                </section>

            </div>
        );
    }
}