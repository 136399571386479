import React, { Component } from 'react';
import CountdownComponent from '../../../common/countdown'
import { Container, Row, Col } from 'reactstrap';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    address?: string;
    date?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Countdown extends Component<IProps, {}> {

    render() {
        const countDown = this.props.data;
        if (countDown !== undefined && countDown !== null) {
            //CountDown Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForCountDownData = countDown.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let dt: Data = { img: "", date: "", address: "" };

            var count = Object.keys(groupByMetaGroupForCountDownData).length;

            for (var i = 1; i <= count; i++) {

                groupByMetaGroupForCountDownData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img= data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Address") {
                        dt.address = data.MetaProperty.value;
                    }
                });
            }
            return (
                <section className="wedding countdown-bg bg set-relative">
                    <Container>
                        <Row>
                            <Col md="8" className="offset-md-2">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src="/assets/images/wedding-img/bottom.png" />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{countDown.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>
                                            {countDown.LandingPageSectionDescription}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="8" className="offset-xl-2">
                                <div className="wedding-time ">
                                    <div>
                                        <div className="timimg-wedding">
                                            <CountdownComponent />
                                        </div>
                                    </div>
                                </div>
                                <div className="schedule text-center">
                                    <h3 className="m-b-15 zexson-text">
                                        <i aria-hidden="true" className="fa fa-map-marker m-r-10"></i> {dt.address}
                                    </h3>
                                    <h4 className="schedule-date">
                                        <i aria-hidden="true" className="fa fa-calendar m-r-10"></i>{dt.date}
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}