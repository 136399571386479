import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getList } from '../../../common/genericApi';
import { AppThunk, RootState } from '../../../store/store';
import { LandingPageDTO, LandingPageSection, LandingPageSectionBranding, LandingPageType, TemplateBrandingKit } from '../../../server/LandingPageModel';
import { getCLS } from 'web-vitals';

export interface Conference1LPState {
    LandingPageDTO: LandingPageDTO;
    TemplateBrandingKit: TemplateBrandingKit;
    sliderIsOpen: boolean,
    sliderSelectedTab: string,
    sectionId?: number,
    loading: boolean;
    error: string;
}


const initialState: Conference1LPState = {
    LandingPageDTO: {
        LandingPageId: 0,
        LandingPageName: "",
        LandingPageDescription: "",
        isPrivate: false,
        LandingPageType: {} as LandingPageType,
        LandingPageSections: [] as LandingPageSection[],
    },
    TemplateBrandingKit: {} as TemplateBrandingKit,
    sliderIsOpen: false,
    sliderSelectedTab: 'kit',
    loading: false,
    error: "",
}

export const conference1Slice = createSlice({
    name: 'conference1',
    initialState,
    reducers: {
        GetStart(state) {
            state.loading = true;

        },

        SelectThemeSettings(state, action: PayloadAction<{ id: number, isOpen: boolean }>) {
            state.sectionId = action.payload.id;
            state.sliderIsOpen = action.payload.isOpen;
            state.sliderSelectedTab = 'cust';
        },
        BrandingSlider(state, action: PayloadAction<{ isOpen: boolean, tabEvent: string }>) {
            state.sliderIsOpen = action.payload.isOpen;
            state.sliderSelectedTab = action.payload.tabEvent
        },
        SetThemeSettings(state, action: PayloadAction<{ LandingPageSectionBranding: LandingPageSectionBranding, sectionIndex: number }>) {
            state.LandingPageDTO.LandingPageSections[action.payload.sectionIndex].LandingPageSectionBranding = action.payload.LandingPageSectionBranding;
        },
        BrandingSliderTab(state, action: PayloadAction<{ eventKey: string }>) {
            state.sliderSelectedTab = action.payload.eventKey;
        },
        LandingPageReceived(state, action: PayloadAction<LandingPageDTO>) {
            state.LandingPageDTO = action.payload;
            state.loading = false;
        },
        KitsReceived(state, action: PayloadAction<TemplateBrandingKit>) {
            state.TemplateBrandingKit = action.payload;
            state.loading = false;
        },

    },
})

// Action creators are generated for each case reducer function
export const {
    GetStart,
    LandingPageReceived,
    KitsReceived,
    SelectThemeSettings,
    BrandingSlider,
    BrandingSliderTab,
    SetThemeSettings
} = conference1Slice.actions

export default conference1Slice.reducer


export const conferenceActionCreators = {

    fetchConference1LP: (): AppThunk => async dispatch => {
        try {
            dispatch(GetStart)
            const Result = await getList<LandingPageDTO>('api/LandingPages/Conference1')

            dispatch(LandingPageReceived(Result))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    },

    selectThemeSettings: (id: number, isOpen: boolean): AppThunk => async dispatch => {
        try {
            dispatch(SelectThemeSettings({ id, isOpen }))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    },

    closeBrandingSlider: (isOpen: boolean, tabEvent: string): AppThunk => async dispatch => {
        try {
            dispatch(BrandingSlider({ isOpen, tabEvent }))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    },

    setThemeSettings: (landingPageSectionBranding: LandingPageSectionBranding, sectionIndex: number): AppThunk => async dispatch => {
        try {
            dispatch(SetThemeSettings({ LandingPageSectionBranding: landingPageSectionBranding, sectionIndex }))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    },

    setTab: (tabEvent: string): AppThunk => async dispatch => {
        try {
            dispatch(BrandingSliderTab({ eventKey: tabEvent }))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    },

    fetchBrandingKits: (): AppThunk => async dispatch => {
        try {
            dispatch(GetStart)
            const Result = await getList<TemplateBrandingKit>('api/BrandingKit/BrandingKit')

            dispatch(KitsReceived(Result))
        } catch (err: any) {
            //dispatch(getSiteSettingsFailure(err))
        }
    }

}