import React from 'react';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    heading?: string;
    date?: string;
    comment?: string;
    description?: string;
    button?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Blog extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const blog = this.props.data;
        if (blog !== undefined && blog !== null) {

            var branding = blog.LandingPageSectionBranding;
            document.body.style.setProperty('--BlogData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--BlogData-color-b-one', branding.ColorBOne)
            document.body.style.setProperty('--BlogData-color-b-two', branding.ColorBTwo)
            document.body.style.setProperty('--BlogData-color-c-one', branding.ColorCOne)
            document.body.style.setProperty('--BlogData-color-c-two', branding.ColorCTwo)
            document.body.style.setProperty('--BlogData-color-c-three', branding.ColorCThree)
            document.body.style.setProperty('--BlogData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--BlogData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--BlogData-a-fontStyle', branding.FontAStyle)
            document.body.style.setProperty('--BlogData-a-fontBold', branding.FontABold)
            document.body.style.setProperty('--BlogData-b-fontFamily', branding.FontBFamily)
            document.body.style.setProperty('--BlogData-b-fontSize', branding.FontBSize + "px")
            document.body.style.setProperty('--BlogData-b-fontStyle', branding.FontBStyle)
            document.body.style.setProperty('--BlogData-b-fontBold', branding.FontBBold)
            document.body.style.setProperty('--BlogData-c-fontFamily', branding.FontCFamily)
            document.body.style.setProperty('--BlogData-c-fontSize', branding.FontCSize + "px")
            document.body.style.setProperty('--BlogData-c-fontStyle', branding.FontCStyle)
            document.body.style.setProperty('--BlogData-c-fontBold', branding.FontCBold)


            //Blog Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForBlogData = blog.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let blogDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForBlogData).length;

            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };

                groupByMetaGroupForBlogData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Date") {
                        dt.date = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Comment") {
                        dt.comment = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Description") {
                        dt.description = data.MetaProperty.value;
                    }
                    if (data.MetaPropertyType === "Button") {
                        dt.button = data.MetaProperty.value;
                    }

                });
                blogDataObj.push(dt);
            }
            return (
                <div id="blog">
                    <div className="act-blog">

                        <div className="container">

                            <div className="row">

                                <div className="col-12">

                                    <h2 className="elementblog-title elementblog-title2">{blog.LandingPageSectionHeading}</h2>

                                    <div className="row pt-3">
                                        {blogDataObj.map((item, i) => {
                                            return (
                                                <div className="col-md-3 col-sm-6" key={i}>
                                                    <div className="blog-item ">
                                                        <div className="blog-image">
                                                            <img src={item.img} className="attachment-medium wp-post-image" />
                                                        </div>
                                                        <div className="blog-description">
                                                            <h3><a href="#">{item.heading}</a></h3>
                                                            <span className="tzblog_meta">
                                                                {item.date}                            /
                                                                <a href="#" title="Comment on Nulla facilisis vitae">{item.comment}</a>
                                                            </span>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}