import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as AppState from '../../store/store';
import { conferenceActionCreators } from './state/conferenceSlice'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../public/assets/conference1/scss/style.scss';
import SectionSelector from './SectionSelector';
import ThemeSettings from './components/branding/ThemeSettings';

import { Footer } from './components/Footer';

interface IProps extends PropsFromRedux {
};

class Conference1 extends Component<IProps> {

    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        this.props.getConference1LP();
    }
    EditClick = (id: any) => {
        this.props.setThemeSettings(id, true)
    }
    render() {
        const initialValues = this.props.LandingPageDTO;

        return (
            <>
                <ThemeSettings />
            <div className="act-Conf-1">

                {initialValues.LandingPageSections.map((item, idx) => {
                    return (
                        <div className="section-edit-border">
                            <button onClick={() => this.EditClick(item.LandingPageSectionId)} className="section-edit-button"><i className="fa fa-pencil" /> EDIT</button>

                            <SectionSelector
                                sectionName={item.LandingPageSectionName}
                                key={idx + idx + idx}
                                data={item}
                            />
                        </div>
                    )
                })}
                <Footer />
                </div>
                </>
        );
    }
}


const mapStateToProps = (state: AppState.RootState) => ({
    ...state.conference1,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        getConference1LP: () => dispatch(conferenceActionCreators.fetchConference1LP()),
        setThemeSettings: (id: number, isOpen: boolean) => dispatch(conferenceActionCreators.selectThemeSettings(id, isOpen))
    }
}

const connector = connect(
    mapStateToProps,
    mapDispatchToProps
);

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Conference1);