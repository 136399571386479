import React, { Component } from 'react';
import {Container,Row,Col} from 'reactstrap';
import BottomImage from '../../../../public/assets/images/wedding-img/bottom.png';
import { LandingPageSection } from '../../../server/LandingPageModel';

interface IProps {
    data: LandingPageSection;
}
export class Subscribe extends Component<IProps, {}> {

    render() {
        const subscribe = this.props.data;
        if (subscribe !== undefined && subscribe !== null) {
            return (
                <section className="wedding subscribe attend-bg bg set-relative">
                    <Container>
                        <Row>
                            <Col lg="6" md="8" className="offset-md-2 offset-lg-3">
                                <div className="title">
                                    <img alt="" className="img-fluid title-img"
                                        src={BottomImage} />
                                    <div className="main-title">
                                        <h2 className="gradient-text">{subscribe.LandingPageSectionHeading}</h2>
                                    </div>
                                    <div className="sub-title">
                                        <p>
                                            {subscribe.LandingPageSectionDescription}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="p-b-50 m-b-50">
                            <Col lg="6" className="offset-lg-3">
                                <div className="subscribe">
                                    <div className="center-content">
                                        <div className="form-group">
                                            <div className="d-flex">
                                                <input className="form-control" name="email" placeholder="Please Enter Your Email Address"
                                                    type="email" />
                                                <div className="button-primary">
                                                    <button className=" btn btn-default btn-gradient text-white text-capitalize"
                                                        type="submit">send me
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            )
        }
    }
}