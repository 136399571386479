import React from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
} from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';

import { LandingPageSection } from '../../../server/LandingPageModel';

interface Data {
    img?: string;
    heading?: string;
}

interface IProps {
    data: LandingPageSection;
}

export class Slider extends React.Component<IProps, {}> {

    constructor(props: any) {
        super(props);
    }

    render() {
        const slider = this.props.data;
        if (slider !== undefined && slider !== null) {
            var branding = slider.LandingPageSectionBranding;
            document.body.style.setProperty('--SliderData-color-a-one', branding.ColorAOne)
            document.body.style.setProperty('--SliderData-a-fontFamily', branding.FontAFamily)
            document.body.style.setProperty('--SliderData-a-fontSize', branding.FontASize + "px")
            document.body.style.setProperty('--SliderData-a-fontStyle', branding.FontAStyle)
            //Slider Data Object Creating Because we have randow Meta Data in the Section
            var groupByMetaGroupForSliderData = slider.MetaData.reduce(function (r, a) {
                r[a.MetaPropertyGroup] = r[a.MetaPropertyGroup] || [];
                r[a.MetaPropertyGroup].push(a);
                return r;
            }, Object.create(null));

            let sliderDataObj: Data[] = [];

            var count = Object.keys(groupByMetaGroupForSliderData).length;
            var multiImg = 0;
            for (var i = 1; i <= count; i++) {

                let dt: Data = { img: "" };

                groupByMetaGroupForSliderData["" + i + ""].map((data: any) => {

                    if (data.MetaPropertyType === "Image") {
                        dt.img = slider.LandingPageSectionBranding.MultipleImages[multiImg];
                    }
                    if (data.MetaPropertyType === "Heading") {
                        dt.heading = data.MetaProperty.value;
                    }

                });
                sliderDataObj.push(dt);
                multiImg = multiImg + 1;
            }
        return (
            <div id="slider">

                <div className='container-fluid px-0'>
                    <div className="row px-0 m-0">
                        <div className="col-12 px-0 m-0">
                            <Carousel indicators={false} fade interval={6000}>
                                {sliderDataObj.map((item, i) => {
                                    return (

                                        <Carousel.Item key={i} >
                                        <img
                                            className="d-block"
                                            src={item.img}
                                            alt=""
                                        />
                                        <Carousel.Caption>
                                                <h1 className="act-cap"> {item.heading} </h1>
                                        </Carousel.Caption>
                                        </Carousel.Item>
                                        )
                                })}
                             
                            </Carousel>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    }
}