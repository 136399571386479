import React, { Component } from 'react';
import { NavbarHeader } from './components/NavbarHeader';
import { Slider } from './components/Slider';
import { About } from './components/About';
import { Speaker } from './components/Speaker';
import { Schedule } from './components/Schedule';
import { Sponser } from './components/Sponser';
import { Pricing } from './components/Pricing';
import { NewsLetter } from './components/NewsLetter';
import { Footer } from './components/Footer';
import { Map } from './components/Map';


interface IProps {
    sectionName: string; // section
    data?: any;
}

class SectionSelector extends Component<IProps> {

    components = {
        NavData: NavbarHeader,
        HeaderData: Slider,
        AboutData: About,
        SpeakerData: Speaker,
        ScheduleData: Schedule,
        PricingData: Pricing,
        SponsorData: Sponser,
        NewsLetterData: NewsLetter,
        FooterData: Footer,
        MapData: Map,

    };

    render() {
        const TagName = (this.components as any)[this.props.sectionName || 'empty'];
        return (
            <TagName data={this.props.data} />
        );
    }
}

export default SectionSelector;